import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./themeRegionDiv.css";
import { MdNightlight, MdSunny } from "react-icons/md";
import { SlGlobe } from "react-icons/sl";

class ThemeRegionDiv extends Component {
  state = {
    isSticky: false,
    regionIconClass: "region-text",
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.isSticky) {
        this.setState({
          regionIconClass: "region-text sticky",
          isSticky: this.props.isSticky,
        });
      } else {
        this.setState({
          regionIconClass: "region-text",
          isSticky: this.props.isSticky,
        });
      }
    }
  }

  toggleTheme = (theme) => {
    this.props.changeTheme(theme);
  };

  getRegionDivClass = () => {
    var classname = "region-toggle-div";

    if (process.env.REACT_APP_THEMES != "2") {
      classname = classname + " one-theme";
    }

    if (this.state.isSticky) {
      classname = classname + " region-toggle-div-sticky";
    }

    console.log("classname", classname);
    return classname;
  };

  render() {
    return (
      <React.Fragment>
        <div class="theme-region-div">
          {process.env.REACT_APP_THEMES == "2" ? (
            <div class="theme-toggle-div">
              <div
                class={this.state.isSticky ? "background sticky" : "background"}
              ></div>
              {this.props.theme == "dark" ? (
                <MdNightlight
                  className={
                    this.state.isSticky
                      ? "theme-toggle-icon sticky"
                      : "theme-toggle-icon"
                  }
                  onClick={this.toggleTheme.bind(this, "light")}
                ></MdNightlight>
              ) : (
                <MdSunny
                  className={
                    this.state.isSticky
                      ? "theme-toggle-icon sticky"
                      : "theme-toggle-icon"
                  }
                  onClick={this.toggleTheme.bind(this, "dark")}
                ></MdSunny>
              )}
            </div>
          ) : null}
          <div class={this.getRegionDivClass()}>
            <div class="theme-toggle-div" onClick={this.props.handleModalOpen}>
              <div
                class={this.state.isSticky ? "background sticky" : "background"}
              ></div>
              <SlGlobe
                class={
                  this.state.isSticky
                    ? "region-toggle-icon sticky"
                    : "region-toggle-icon"
                }
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ThemeRegionDiv);
