import React, { Component } from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import "./dashboard.css";
import DashboardService from "./dashboardService";
import Layout from "../../components/layout/layout";
import Footer from "../../components/footer/footer";
import Portfolio from "../portfolio/portfolio";
import AssetDetail from "../asset-detail/assetDetail";
import Deposit from "../deposit/deposit";
import Withdraw from "../withdraw/withdraw";
import DepositWithdraw from "../deposit-withdraw/depositWithdraw";
import PayMe from "../pay-me/payMe";
import PaymentOptions from "../payments/paymentOptions/paymentOptions";
import PaymentInAppUser from "../payments/paymentInAppUser/paymentInAppUser";
import Vendors from "../vendors/vendors/vendors";
import VendorDetail from "../vendors/vendorDetail/vendorDetail";
import NewVendor from "../vendors/newVendor/newVendor";
import UpdateVendor from "../vendors/updateVendor/updateVendor";
import NewVendorPaymentMethod from "../vendors/newVendorPaymentMethod/newVendorPaymentMethod";
import UpdateVendorPaymentMethod from "../vendors/updateVendorPaymentMethod/updateVendorPaymentMethod";
import VerificationModal from "../../components/verification-modal/verificationModal";
import PaymentInfoModal from "../../components/payment-info-modal/paymentInfoModal";
import PayVendor from "../vendors/payVendor/payVendor";
import Home from "../home/home";
import Settings from "../settings/settings/settings";
import IdVerification from "../settings/idVerification/idVerification";
import ChangeEmail from "../settings/changeEmail/changeEmail";
import VerifyEmail from "../settings/verifyEmail/verifyEmail";
import ChangePhone from "../settings/changePhone/changePhone";
import VerifyPhone from "../settings/verifyPhone/verifyPhone";
import Transactions from "../transactions/transactions";
import InsideResult from "../result/insideResult/insideResult";
import Conversion from "../conversion/conversion";
import { utils } from "../../utils/utils";

class Dashboard extends Component {
  state = {
    linkedAccount: false,
    account: null,
    goBackNavBar: false,
    goBackUrl: "/dashboard",
    phoneVerified: null,
    emailVerified: null,
    idVerified: null,
    showVerifyModal: null,
    showPaymentInfoModal: null,
  };

  service = new DashboardService();

  componentDidMount() {
    if (utils.getTokenFromStorage()) {
      this.setState({
        account: JSON.parse(utils.getTokenFromStorage()),
      });
      this.getClientConfig(true);
    } else {
      utils.goToURL(this.props, "/");
    }
  }

  componentDidUpdate() {}

  changeGoBackNavBar = (value, url) => {
    this.setState({
      goBackNavBar: value,
      goBackUrl: url,
    });
  };

  toggleModalVerify = (value) => {
    this.setState({
      showVerifyModal: value,
    });
  };

  toggleModalPaymentInfo = (value) => {
    this.setState({
      showPaymentInfoModal: value,
    });
  };

  //firstLoad is to open modal or not
  getClientConfig = (firstLoad) => {
    this.service.getClientConfig(this.setState.bind(this), firstLoad);
  };

  render() {
    return (
      <React.Fragment>
        <Layout
          account={this.state.account}
          goBackNavBar={this.state.goBackNavBar}
          goBackUrl={this.state.goBackUrl}
          changeTheme={this.props.changeTheme}
          theme={this.props.theme}
          handleModalOpen={this.props.handleModalOpen}
          region={this.props.region}
          localizedText={this.props.localizedText}
        ></Layout>
        <Switch>
          <Route
            exact
            path="/dashboard/settings/id-verification"
            render={(props) => (
              <div class="welcome-div-container">
                <IdVerification
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  toggleModalVerify={this.toggleModalVerify}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                  getClientConfig={this.getClientConfig}
                ></IdVerification>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/deposit"
            render={(props) => (
              <div class="welcome-div-container">
                <Deposit
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></Deposit>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/withdraw"
            render={(props) => (
              <div class="welcome-div-container">
                <Withdraw
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></Withdraw>
              </div>
            )}
          />

          {process.env.REACT_APP_ENV != "prod" ? (
            <Route
              exact
              path="/dashboard/hidden/deposit-dev"
              render={(props) => (
                <div class="welcome-div-container">
                  <DepositWithdraw
                    account={this.state.account}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></DepositWithdraw>
                </div>
              )}
            />
          ) : null}

          <Route
            exact
            path="/dashboard/pay-me"
            render={(props) => (
              <div class="welcome-div-container">
                <PayMe
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></PayMe>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/payment-options"
            render={(props) => (
              <div class="welcome-div-container">
                <PaymentOptions
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></PaymentOptions>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/payment-in-app-users/"
            render={(props) => (
              <div class="welcome-div-container">
                <PaymentInAppUser
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></PaymentInAppUser>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/payment/vendors"
            render={(props) => (
              <div class="welcome-div-container">
                <Vendors
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></Vendors>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/payment/vendors/create"
            render={(props) => (
              <div class="welcome-div-container">
                <NewVendor
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></NewVendor>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/payment/vendors/update/:id"
            render={(props) => (
              <div class="welcome-div-container">
                <UpdateVendor
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></UpdateVendor>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/payment/vendors/payment-methods"
            render={(props) => (
              <div class="welcome-div-container">
                <NewVendorPaymentMethod
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></NewVendorPaymentMethod>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/payment/vendors/:vendor_id/payment-methods/update/:payment_method_id"
            render={(props) => (
              <div class="welcome-div-container">
                <UpdateVendorPaymentMethod
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></UpdateVendorPaymentMethod>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/payment/vendors/pay"
            render={(props) => (
              <div class="welcome-div-container">
                <PayVendor
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></PayVendor>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/payments/vendors/detail/:id"
            render={(props) => (
              <div class="welcome-div-container">
                <VendorDetail
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  theme={this.props.theme}
                ></VendorDetail>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/settings"
            render={(props) => (
              <div class="welcome-div-container">
                <Settings
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  theme={this.props.theme}
                ></Settings>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/settings/change-email"
            render={(props) => (
              <div class="welcome-div-container">
                <ChangeEmail
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  getClientConfig={this.getClientConfig}
                ></ChangeEmail>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/settings/verify-email"
            render={(props) => (
              <div class="welcome-div-container">
                <VerifyEmail
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  getClientConfig={this.getClientConfig}
                ></VerifyEmail>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/settings/change-phone"
            render={(props) => (
              <div class="welcome-div-container">
                <ChangePhone
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  getClientConfig={this.getClientConfig}
                ></ChangePhone>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/settings/verify-phone"
            render={(props) => (
              <div class="welcome-div-container">
                <VerifyPhone
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  getClientConfig={this.getClientConfig}
                ></VerifyPhone>
              </div>
            )}
          />

          {process.env.REACT_APP_ENV != "prod" ? (
            <Route
              exact
              path="/dashboard/hidden/transactions"
              render={(props) => (
                <div class="welcome-div-container">
                  <Transactions
                    account={this.state.account}
                    changeGoBackNavBar={this.changeGoBackNavBar}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                    phoneVerified={this.state.phoneVerified}
                    emailVerified={this.state.emailVerified}
                    idVerified={this.state.idVerified}
                  ></Transactions>
                </div>
              )}
            />
          ) : null}

          <Route
            exact
            path="/dashboard/portfolio/:tab"
            render={(props) => (
              <div class="welcome-div-container">
                <Portfolio
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  theme={this.props.theme}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></Portfolio>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/portfolio/asset/:asset"
            render={(props) => (
              <div class="welcome-div-container">
                <AssetDetail
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  theme={this.props.theme}
                ></AssetDetail>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/result/:type/:success"
            render={(props) => (
              <div class="welcome-div-container">
                <InsideResult
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  theme={this.props.theme}
                ></InsideResult>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard/conversion"
            render={(props) => (
              <div class="welcome-div-container">
                <Conversion
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></Conversion>
              </div>
            )}
          />

          <Route
            exact
            path="/dashboard"
            render={(props) => (
              <div class="welcome-div-container">
                <Home
                  account={this.state.account}
                  changeGoBackNavBar={this.changeGoBackNavBar}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  phoneVerified={this.state.phoneVerified}
                  emailVerified={this.state.emailVerified}
                  idVerified={this.state.idVerified}
                ></Home>
              </div>
            )}
          />

          <Route path="/" render={(props) => <Redirect to="/dashboard" />} />
        </Switch>
        <VerificationModal
          localizedText={this.props.localizedText}
          showVerifyModal={this.state.showVerifyModal}
          toggleModalVerify={this.toggleModalVerify}
          phoneVerified={this.state.phoneVerified}
          emailVerified={this.state.emailVerified}
          idVerified={this.state.idVerified}
        ></VerificationModal>
        <PaymentInfoModal
          localizedText={this.props.localizedText}
          showVerifyModal={this.state.showPaymentInfoModal}
          toggleModalVerify={this.toggleModalPaymentInfo}
        ></PaymentInfoModal>
        <Footer
          region={this.props.region}
          localizedText={this.props.localizedText}
          theme={this.props.theme}
        ></Footer>
      </React.Fragment>
    );
  }
}

export default withRouter(Dashboard);
