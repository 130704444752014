import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./paymentInAppUser.css";
import PaymentInAppUserService from "./paymentInAppUserService";
import GoBackButton from "../../../components/go-back-button/goBackButton";
import TotalBalance from "../../../components/total-balance/totalBalance";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { RiStarSFill } from "react-icons/ri";
import { Spinner } from "react-bootstrap";
import TransactionConfirmation from "../../../components/transactionConfirmation/transactionConfirmation";
import Result from "../../../components/result/result";
import { utils } from "../../../utils/utils.jsx";
import ErrorMessageComponent from "../../../components/errorMessage/errorMessage.jsx";

class PaymentInAppUser extends Component {
  state = {
    account: {},

    id:
      process.env.REACT_APP_ENV != "prod"
        ? "usertest1.bloxcross@gmail.com"
        : "",
    code: "",
    message: "",

    name: "",
    email: "",
    phone: "",

    searchIdDescriptionType: "",
    errorMessageType: "",

    currency: "",
    listCurrencies: [],

    amount: "",
    authChoice: "",

    loadingSent: false,
    loadingPeer: false,

    invalidID: false,

    invalidCurrency: false,
    invalidAmount: false,
    invalidCode: false,

    resultSuccess: true,

    stage: 1,
  };

  service = new PaymentInAppUserService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    this.service.getDefaultPortfolio(this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.tryToGoBackStage
      )
    );

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });

        if (this.props.phoneVerified != null && !this.props.phoneVerified) {
          utils.goToURL(this.props, "/dashboard/settings/verify-phone");
        } else if (
          this.props.emailVerified != null &&
          !this.props.emailVerified
        ) {
          utils.goToURL(this.props, "/dashboard/settings/verify-email");
        } else if (this.props.idVerified != null && !this.props.idVerified) {
          utils.goToURL(this.props, "/dashboard/settings/id-verification");
        }
      }
    }
  }

  handleChangeForInvalidAmount = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        utils.handleChangeForInvalidAmount(
          this.state.listCurrencies,
          this.state.amount,
          this.state.currency,
          this.setMessages.bind(
            this,
            "payment-in-app-user-error-invalid-amount"
          ),
          this.setState.bind(this)
        );
      }
    );
  };

  searchID = () => {
    this.setState(
      {
        searchIdDescription: "",
      },
      () => {
        if (this.state.id != "") {
          this.setState(
            {
              stage: 1,
              loadingPeer: true,
            },
            () => {
              this.service.getClientConfig(this.setState.bind(this));
              this.service.checkPeer(
                this.state,
                this.setState.bind(this),
                this.setMessagesID.bind(this)
              );
            }
          );
        } else {
          this.setState(
            {
              invalidID: this.state.id == "",
            },
            () => {
              this.setMessagesID("*please-enter-a-valid-email-or-phone-number");
            }
          );
        }
      }
    );
  };

  confirmPhone = () => {
    this.service.confirmPhone(this.setState.bind(this));
  };

  moveToConfirmation = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.amount != "" && this.state.currency != "") {
          console.log(
            "comp1",
            this.state.listCurrencies.filter(
              (currency) => currency.base_currency === this.state.currency
            ).length > 0
          );
          console.log(
            "comp2",
            this.state.listCurrencies.filter(
              (element) => element.base_currency == this.state.currency
            )[0].qty >= Number(this.state.amount)
          );
          if (
            this.state.listCurrencies.filter(
              (currency) => currency.base_currency === this.state.currency
            ).length > 0 &&
            this.state.listCurrencies.filter(
              (element) => element.base_currency == this.state.currency
            )[0].qty >= Number(this.state.amount)
          ) {
            this.setState({
              invalidCurrency: false,
              invalidAmount: false,
              stage: 3,
            });
          } else {
            this.setMessages(
              "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
            );
            this.setState({
              invalidAmount: true,
            });
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidAmount: this.state.amount == "",
            invalidCurrency: this.state.currency == "",
          });
        }
      }
    );
  };

  goToAddFavoritePaymentMethod = () => {
    this.props.history.push({
      pathname: "/dashboard/favorite-payment-method",
    });
  };

  payPeer = (code) => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (code != "") {
          this.setState(
            {
              loadingSent: true,
            },
            () => {
              this.service.payPeer(
                this.state.stage,
                this.state.phone,
                this.state.amount,
                this.state.currency,
                code,
                this.setState.bind(this)
              );
            }
          );
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCode: code == "",
          });
        }
      }
    );
  };

  moveStage = (value) => {
    this.setState({
      stage: this.state.stage + value,
    });
  };

  setMessagesID = (type) => {
    if (type != "") {
      this.setState({
        searchIdDescriptionType: type,
      });
    }
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  goBackStage = () => {
    this.setState({
      stage: this.state.stage - 1,
      loadingPeer: "",
      invalidCode: false,
      amount: "",
      invalidAmount: false,
      currency: "",
      invalidCurrency: false,
      errorMessageType: "",
    });
  };

  tryToGoBackStage = () => {
    if (this.state.stage == 1) {
      utils.goToURL(this.props, "/dashboard/payment-options");
    } else {
      this.goBackStage();
    }
  };

  getResultMessage = () => {
    if (this.state.resultSuccess) {
      return this.props.localizedText[
        "you-have-successfully-payed-an-in-app-user"
      ];
    } else {
      return this.props.localizedText[
        "unfortunately,-there-was-a-problem-with-the-payment"
      ];
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="payment-input-container">
          <div class="payment-input-body container-padding">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={this.tryToGoBackStage}
              ></GoBackButton>
            </div>
            <div class="middle-container">
              {this.state.stage == 1 || this.state.stage == 2 ? (
                <div>
                  <TotalBalance
                    account={this.state.account}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                  ></TotalBalance>
                  <div class="section">
                    <div class="id-input">
                      <div class="background"></div>
                      <div class="content">
                        <div class="form-div">
                          <Form.Group className="mb-3 input-payment-id">
                            <Form.Label className="input-label">
                              {this.props.localizedText["to"]}
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                className="input-text"
                                type="text"
                                name="id"
                                placeholder="Please enter a phone or email"
                                value={this.state.id}
                                isInvalid={this.state.invalidID}
                                onChange={(e) =>
                                  utils.handleChange(
                                    e,
                                    this.setState.bind(this)
                                  )
                                }
                              />

                              <Button
                                className="search-button"
                                onClick={this.searchID.bind(this)}
                                invalid={this.state.invalidID}
                              >
                                {this.props.localizedText["search"]}
                              </Button>
                            </InputGroup>
                          </Form.Group>
                        </div>
                        {this.state.stage == 1 ? (
                          <div class="form-div">
                            {this.state.loadingPeer ? (
                              <Spinner className="loader" />
                            ) : (
                              <div>
                                {this.state.searchIdDescriptionType != "" ? (
                                  <div
                                    class={
                                      "description-text" +
                                      (this.state.invalidID ? " danger" : "")
                                    }
                                  >
                                    {
                                      this.props.localizedText[
                                        this.state.searchIdDescriptionType
                                      ]
                                    }
                                  </div>
                                ) : (
                                  <div class={"description-text"}>
                                    {
                                      this.props.localizedText[
                                        "*conveniently-make-your-payment-using-a-secure-online-payment-option"
                                      ]
                                    }
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <div class="account-info">
                              <div class="image"></div>
                              <div class="text">
                                <div class="title">{this.state.name}</div>
                                <div class="id">{this.state.email}</div>
                                <div class="type">{this.state.phone}</div>
                              </div>
                            </div>
                            <div
                              class="favorite-div"
                              onClick={this.goToAddFavoritePaymentMethod}
                            >
                              <RiStarSFill class="favorite-icon"></RiStarSFill>
                              <div class="add-text">
                                {
                                  this.props.localizedText[
                                    "save-to-a-favorite-contact"
                                  ]
                                }
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {this.state.stage == 1 ? (
                      <div class="value-input-waiting">
                        <div class="background"></div>
                        <div class="content">
                          {this.props.localizedText["waiting-for-id"]}
                        </div>
                      </div>
                    ) : (
                      <div class="right-div">
                        <div class="value-input">
                          <Form.Group className="mb-3 form-group">
                            <Form.Label className="input-label-outside-div">
                              {this.props.localizedText["pay-with"]}
                            </Form.Label>
                            <InputGroup className="mb-3 input-field">
                              <Form.Select
                                variant="outline-secondary"
                                onChange={this.handleChangeForInvalidAmount}
                                name="currency"
                                isInvalid={this.state.invalidCurrency}
                                style={{
                                  width: "100%",
                                  flex: "0 1 auto",
                                  textAlign: "start",
                                }}
                              >
                                <option></option>
                                {this.state.listCurrencies.map((currency) => (
                                  <option value={currency.base_currency}>
                                    {currency.base_currency +
                                      " (" +
                                      utils.roundDecimals(
                                        currency.base_currency,
                                        currency.qty
                                      ) +
                                      ")"}
                                  </option>
                                ))}
                              </Form.Select>
                            </InputGroup>
                          </Form.Group>

                          <Form.Group className="mb-3 form-group">
                            <Form.Label className="input-label-outside-div">
                              {this.props.localizedText["amount"]}
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <FormControl
                                type="text"
                                name="amount"
                                value={this.state.amount}
                                isInvalid={this.state.invalidAmount}
                                onChange={this.handleChangeForInvalidAmount}
                                className="input-text no-background-input-outside"
                                autoComplete="off"
                              />
                              <InputGroup.Text
                                className={
                                  this.state.invalidAmount
                                    ? "outside-input-symbol input-symbol-danger"
                                    : "outside-input-symbol"
                                }
                              >
                                {this.state.currency}
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>
                    )}
                  </div>
                  {this.state.stage == 1 ? null : (
                    <div>
                      <ErrorMessageComponent
                        errorMessage={
                          this.props.localizedText[this.state.errorMessageType]
                        }
                      ></ErrorMessageComponent>
                      <div class="buttons-div fade-in-up">
                        <Button
                          className="primary-button outside-primary-button"
                          onClick={this.moveToConfirmation}
                        >
                          {this.props.localizedText["pay"]}
                        </Button>

                        <Button
                          className="secondary-button outside-secondary-button"
                          onClick={utils.goToURL.bind(
                            this,
                            this.props,
                            "/dashboard/payment-options"
                          )}
                        >
                          {this.props.localizedText["cancel"]}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
              {this.state.stage === 3 ? (
                <TransactionConfirmation
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                  account={this.state.account}
                  loadingSent={this.state.loadingSent}
                  amountFrom={this.state.amount}
                  currencyFrom={this.state.currency}
                  amountTo={this.state.amount}
                  currencyTo={this.state.currency}
                  name={this.state.name}
                  id={this.state.email}
                  type={"Direct Pay"}
                  primaryButtonText={
                    this.props.localizedText["confirm-payment"]
                  }
                  primaryButtonFunction={this.payPeer.bind(this)}
                  secondaryButtonText={this.props.localizedText["back"]}
                  secondaryButtonFunction={this.goBackStage}
                  invalidCode={this.state.invalidCode}
                  errorMessage={
                    this.props.localizedText[this.state.errorMessageType]
                  }
                ></TransactionConfirmation>
              ) : null}
              {this.state.stage === 4 ? (
                <Result
                  success={this.state.resultSuccess}
                  secondButton={false}
                  title={
                    this.props.localizedText[
                      utils.getResultTitle(this.state.resultSuccess)
                    ]
                  }
                  message={this.getResultMessage()}
                  primaryButtonText={this.props.localizedText["continue"]}
                  primaryButtonFunction={utils.goToURL.bind(
                    this,
                    this.props,
                    "/dashboard"
                  )}
                ></Result>
              ) : null}
            </div>
            <div class="side-container "></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PaymentInAppUser);
