import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./sidePayDiv.css";
import { FaWallet } from "react-icons/fa";
import { TbArrowsRightLeft } from "react-icons/tb";
import { utils } from "../../utils/utils";

class SidePayDiv extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
    }
  }

  render() {
    return (
      <React.Fragment>
        <div class="operations-div">
          <div
            class="operation-card"
            onClick={utils.goToURL.bind(
              this,
              this.props,
              "/dashboard/deposit-withdraw"
            )}
          >
            <div class="text">
              <div class="title">
                {this.props.localizedText["deposit-and-withdraw"]}
              </div>
              <div class="description">
                {
                  this.props.localizedText[
                    "deposit-and-withdraw-funds-from-your-wallet"
                  ]
                }
              </div>
            </div>
            <FaWallet className="icon"></FaWallet>

            <div class="image image-src-1"></div>
          </div>

          <div
            class="operation-card"
            onClick={utils.goToURL.bind(
              this,
              this.props,
              "/dashboard/payment-options"
            )}
          >
            <div class="text">
              <div class="title">
                {" "}
                {this.props.localizedText["send-payments"]}
              </div>
              <div class="description">
                {
                  this.props.localizedText[
                    "send-payments-to-third-party-wallets-and-external-accounts"
                  ]
                }
              </div>
            </div>
            <TbArrowsRightLeft className="icon"></TbArrowsRightLeft>

            <div class="image image-src-2"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SidePayDiv);
