import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./transactions.css";
import AssetsService from "./transactionsService";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { TabContent } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Accordion from "react-bootstrap/Accordion";
import { ImSearch } from "react-icons/im";
import TotalBalance from "../../components/total-balance/totalBalance";
import SidePayDiv from "../../components/side-pay-div/sidePayDiv";
import SideNotificationsDiv from "../../components/side-notifications/sideNotificationsDiv";

class Transactions extends Component {
  state = {
    isMobileWidth: false,
    name: null,
    account: {},
    listDepositWithdrawHistory: [],
    listDepositWithdrawHistoryShowed: [],
    listPaymentHistory: [],
    listPaymentHistoryShowed: [],
    listSkeleton: [1, 2, 3, 4, 5, 6],
    loadingDepositWithdraw: true,
    loadingPayments: true,
    tabPicked: "deposit-withdraw",
    text: require("./textEn.json"),
  };

  api = new AssetsService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.api.getDepositWithdrawHistoryWithTimer("1", this.setState.bind(this));
    this.api.getPaymentHistory("1", this.setState.bind(this));

    this.checkWindowWidth();
    this.checkRegion();
    window.addEventListener("resize", this.checkWindowWidth);

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    } else {
      //ComponentDidUpdateWill take care of it
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
      if (this.props.region) {
        this.checkRegion();
      }
    }
  }

  vstyle = getComputedStyle(document.body);

  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 600) {
      flag = true;
    } else {
      flag = false;
    }

    this.checkGoBackNavBar();
    this.setState({
      isMobileWidth: flag,
    });
  };

  checkRegion = () => {
    if (this.props.region) {
      if (this.props.region == "en") {
        this.setState({
          text: require("./textEn.json"),
        });
      } else {
        this.setState({
          text: require("./textEs.json"),
        });
      }
    }
  };

  checkGoBackNavBar = () => {
    this.props.changeGoBackNavBar(false, null);
  };

  editSearchTerm = (e) => {
    if (e.target.value != null) {
      this.setState({
        listDepositWithdrawHistoryShowed: this.filterFunction(
          this.state.listDepositWithdrawHistory,
          e.target.value
        ),
      });
    } else {
      this.setState({
        listDepositWithdrawHistoryShowed: this.state.listDepositWithdrawHistory,
      });
    }
  };

  filterFunction = (objects, value) => {
    var filteredObjects = [];
    var lowerCaseName = "";
    for (const i in objects) {
      lowerCaseName = objects[i]["username"].toLowerCase();
      if (lowerCaseName.includes(value.toLowerCase())) {
        filteredObjects.push(objects[i]);
      }
    }

    return filteredObjects;
  };

  skeletonCards = ({ cards }) => (
    <div>
      <div class="div-cards">
        {cards.map(() => (
          <div class="card-transactions">
            <div class="background"></div>
            <div class="content">
              <div class="metadata-column-skeleton">
                <div class=" skeleton-box image-skeleton"></div>
                <div class="text-skeleton">
                  <div class="skeleton-box title-skeleton"></div>
                  <div class="skeleton-box date-skeleton"></div>
                </div>
              </div>
              <div class="operation-number-column-skeleton">
                <div class="skeleton-box  operation-number-title-skeleton"></div>
                <div class="skeleton-box  operation-number-text-skeleton"></div>
              </div>
              <div class="balance-column-skeleton">
                <div class="skeleton-box value-skeleton"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  goToExternalUrl = (url) => {
    window.location.replace(url);
  };

  formatDateUS = (dateISO) => {
    let newDate = null;
    if (dateISO != null) {
      newDate = new Date(dateISO);
    } else {
      newDate = "";
    }
    //only get Month and date (day)
    return newDate.toUTCString().slice(5, 11);
  };

  depositWithdrawHistory = ({ transactions }) => (
    <div>
      <div class="">
        {transactions.map((month, index) => (
          <Accordion
            defaultActiveKey={["0"]}
            className="accordion-transaction-history"
            alwaysOpen
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {this.toMonthString(
                  Object.keys(month)[0],
                  this.state.text["months"]
                )}
              </Accordion.Header>
              <Accordion.Body>
                <div class="div-cards">
                  {month[Object.keys(month)[0]].map((card, index) => (
                    <div class="card-transactions">
                      <div class="background"></div>
                      <div class="content">
                        <div class="metadata-column">
                          {card["type"] === "Deposit" ? (
                            <div class="image">
                              <div class="interior-letter">W</div>
                            </div>
                          ) : (
                            <div class="image image-danger">
                              <div class="interior-letter">D</div>
                            </div>
                          )}

                          <div class="text">
                            <div class="title">{card["method"]}</div>
                            <div class="date">
                              {this.formatDateUS(card["date"])}
                            </div>
                          </div>
                        </div>
                        <div class="operation-number-column">
                          {!this.state.isMobileWidth ? (
                            <span>{this.state.text["operation-number"]}</span>
                          ) : null}
                          <span>{card["operation-number"]}</span>
                        </div>
                        <div class="amount-column">
                          <div
                            class={
                              card["type"] === "Deposit"
                                ? "amount"
                                : "amount amount-danger"
                            }
                          >
                            {"$ " + card["amount"]}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </div>
    </div>
  );

  clickTab = (tabClicked) => {
    this.setState({
      tabPicked: tabClicked,
    });
  };

  toMonthString = (monthKey, months) => {
    var monthName = "";

    if (months != null) {
      if (monthKey === "01") {
        monthName = months[0];
      } else if (monthKey === "02") {
        monthName = months[1];
      } else if (monthKey === "03") {
        monthName = months[2];
      } else if (monthKey === "04") {
        monthName = months[3];
      } else if (monthKey === "05") {
        monthName = months[4];
      } else if (monthKey === "06") {
        monthName = months[5];
      } else if (monthKey === "07") {
        monthName = months[6];
      } else if (monthKey === "08") {
        monthName = months[7];
      } else if (monthKey === "09") {
        monthName = months[8];
      } else if (monthKey === "10") {
        monthName = months[9];
      } else if (monthKey === "11") {
        monthName = months[10];
      } else if (monthKey === "12") {
        monthName = months[11];
      }
    }
    return monthName;
  };

  paymentsHistory = ({ transactions }) => (
    <div>
      {transactions.map((month, index) => (
        <Accordion
          defaultActiveKey={["0"]}
          className="accordion-transaction-history"
          alwaysOpen
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {this.toMonthString(
                Object.keys(month)[0],
                this.state.text.months
              )}
            </Accordion.Header>
            <Accordion.Body>
              <div class="div-cards">
                {month[Object.keys(month)[0]].map((card, index) => (
                  <div class="card-transactions">
                    <div class="background"></div>
                    <div class="content">
                      <div class="metadata-column">
                        {card["type"] === "Deposit" ? (
                          <div class="image">
                            <div class="interior-letter">W</div>
                          </div>
                        ) : (
                          <div class="image image-danger">
                            <div class="interior-letter">D</div>
                          </div>
                        )}

                        <div class="text">
                          <div class="title">{card["method"]}</div>
                          <div class="date">
                            {this.formatDateUS(card["date"])}
                          </div>
                        </div>
                      </div>
                      <div class="operation-number-column">
                        {!this.state.isMobileWidth ? <span>From</span> : null}
                        <span>{card["sender-id"]}</span>
                      </div>
                      <div class="amount-column">
                        <div
                          class={
                            card["type"] === "Deposit"
                              ? "amount"
                              : "amount amount-danger"
                          }
                        >
                          {card["amount"] + " " + card["currency"]}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </div>
  );

  render() {
    return (
      <React.Fragment>
        <div class="transactions-container ">
          <div class="transactions-body">
            <div class="side-container">
              {!this.state.isMobileWidth ? (
                <SidePayDiv
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></SidePayDiv>
              ) : null}{" "}
              {!this.state.isMobileWidth ? (
                <SidePayDiv
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></SidePayDiv>
              ) : null}
            </div>
            <div class="middle-container">
              <div class="container-padding">
                <TotalBalance
                  account={this.state.account}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></TotalBalance>
                {this.state.isMobileWidth ? (
                  <SidePayDiv
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                  ></SidePayDiv>
                ) : null}
                <Tab.Container defaultActiveKey="deposit-withdraw">
                  <div class="tab-header">
                    <div class="tab-buttons">
                      <Nav.Item
                        class={
                          this.state.tabPicked == "deposit-withdraw"
                            ? "tab-button tab-picked"
                            : "tab-button"
                        }
                      >
                        <Nav.Link
                          eventKey="deposit-withdraw"
                          onClick={this.clickTab.bind(this, "deposit-withdraw")}
                        >
                          {this.state.text["tabs"][0]}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        class={
                          this.state.tabPicked == "payments"
                            ? "tab-button tab-picked"
                            : "tab-button"
                        }
                      >
                        <Nav.Link
                          eventKey="payments"
                          onClick={this.clickTab.bind(this, "payments")}
                        >
                          {this.state.text["tabs"][1]}
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                    <div class="filter-search-div">
                      <InputGroup className="search-bar">
                        <ImSearch class="search-icon"></ImSearch>
                        <FormControl
                          onChange={this.editSearchTerm}
                          id="search-bar-entities"
                          placeholder={this.state.text["search"]}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="deposit-withdraw" tabClassName="tab">
                      <TabContent>
                        {!this.state.loadingDepositWithdraw ? (
                          <this.depositWithdrawHistory
                            transactions={
                              this.state.listDepositWithdrawHistoryShowed
                            }
                          ></this.depositWithdrawHistory>
                        ) : (
                          <this.skeletonCards
                            cards={this.state.listSkeleton}
                          ></this.skeletonCards>
                        )}
                      </TabContent>
                    </Tab.Pane>
                    <Tab.Pane eventKey="payments" tabClassName="tab">
                      <TabContent>
                        {!this.state.loadingDepositWithdraw ? (
                          <this.paymentsHistory
                            transactions={this.state.listPaymentHistoryShowed}
                          ></this.paymentsHistory>
                        ) : (
                          <div>
                            <div class="skeleton-card-seperator"></div>
                            <this.skeletonCards
                              cards={this.state.listSkeleton}
                            ></this.skeletonCards>
                          </div>
                        )}
                      </TabContent>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
            <div class="side-container">
              {!this.state.isMobileWidth ? (
                <SideNotificationsDiv
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></SideNotificationsDiv>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Transactions);
