import axios from "axios";
import { utils } from "../utils/utils";

class WalletAPI {
  getNetworks(crypto) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = { coin: crypto };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL +
        "/wallets/get_inbound_networks_for_token",
      requestBody,
      headers
    );
  }

  getWallet(crypto, network) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const requestBody = { coin: crypto, blockchain: network };

    var endpoint = "/wallets/get_inbound_wallet";

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + endpoint,
      requestBody,
      headers
    );
  }
}

export const walletAPI = new WalletAPI();
