import React from "react";
import ReceiptComponent from "../../components/receipt/receipt";
import { utils } from "../../utils/utils";
import "./printPDF.css";

const PrintPDFComponent = (props) => {
  const handleClick = () => {
    utils.goToURL(this.props, "/dashboard");
  };

  return (
    <div class="payment-detail-inner-div">
      <div className="payment-detail-company-header">
        <img
          className="print-img"
          onClick={() => handleClick()}
          src={"/themes/" + props.theme + "/logo.png"}
          alt="Company Logo"
        />
        <div className="company-title">{process.env.REACT_APP_TITLE}</div>
      </div>
      <div className="payment-detail-title">{props.title}</div>

      <ReceiptComponent data={props.receiptData} />

      <div class="payment-detail-info">
        <div class="partial-border"></div>
        <div class="info-row">
          This reference can be provided to the recipient's financial
          institution to track impending transfers
        </div>
        <div class="info-row">
          Receipt created on: {utils.formatDateUSWithTime(new Date())}
        </div>
      </div>
      <div class="payment-detail-company-footer">
        <div class="legal-name">{process.env.REACT_APP_COMPANY_NAME}</div>
        <div class="bottom-info-div">
          <div class="bottom-info right-pipe">
            75 E. Santa Clara St, Suite 900, San Jose, CA 95113
          </div>
          <div class="bottom-info">contact@aionfi.com</div>
        </div>
      </div>
    </div>
  );
};

export default PrintPDFComponent;
