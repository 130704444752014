import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./deposit.css";
import DepositService from "./depositService";
import GoBackButton from "../../components/go-back-button/goBackButton";
import TotalBalance from "../../components/total-balance/totalBalance";
import { Spinner } from "react-bootstrap";
import { utils } from "../../utils/utils.jsx";
import { RiBankFill } from "react-icons/ri";
import { FaMoneyBillWave } from "react-icons/fa";
import { SiBitcoinsv } from "react-icons/si";
import ConversionComponent from "../../components/conversion-component/conversionComponent";
import Modal from "react-bootstrap/Modal";
import { IoIosArrowForward } from "react-icons/io";
import DepositBank from "../../components/deposit/deposit-bank/depositBank.jsx";
import DepositCrypto from "../../components/deposit/deposit-crypto/depositCrypto.jsx";
import DepositCash from "../../components/deposit/deposit-cash/depositCash.jsx";
import Result from "../../components/result/result.jsx";

class Deposit extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    listCurrenciesFiat: [],
    listCryptos: [],

    currency: "",
    crypto: "",

    depositURL: "",
    showDepositModal: false,

    loadingCurrencies: true,
    loadingConversion: false,

    resultSuccess: true,
    resultMessage: "",

    stage: 1,
  };

  service = new DepositService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("this.props.history", this.props.history);
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    this.service.getCurrencies(this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.tryToGoBackStage
      )
    );

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });

        if (this.props.phoneVerified != null && !this.props.phoneVerified) {
          utils.goToURL(this.props, "/dashboard/settings/verify-phone");
        } else if (
          this.props.emailVerified != null &&
          !this.props.emailVerified
        ) {
          utils.goToURL(this.props, "/dashboard/settings/verify-email");
        } else if (this.props.idVerified != null && !this.props.idVerified) {
          utils.goToURL(this.props, "/dashboard/settings/id-verification");
        }
      }
    }
  }

  moveStage = (stage) => {
    this.setState({
      stage: stage,
    });
  };

  goBackStage = () => {
    this.setState({
      stage: 1,
      currency: "",
      crypto: "",
    });
  };

  tryToGoBackStage = () => {
    if (this.state.stage == 1) {
      utils.goToURL(this.props, "/dashboard");
    } else {
      this.goBackStage();
    }
  };

  goToResultStage = (resultSuccess, resultMessage) => {
    this.setState({
      resultSuccess: resultSuccess,
      resultMessage: resultMessage,
      stage: "result",
    });
  };

  copyText = () => {
    this.setState({
      inboundAddressCopied: true,
    });
    navigator.clipboard.writeText(this.state.inboundAddress);
  };

  handleDepositCloseModal = () => {
    this.setState({
      showDepositModal: false,
    });
  };

  getWidthForModal = () => {
    if (this.state.width > 1200) {
      return "600";
    } else if (this.state.width < 1200 && this.state.width > 820) {
      return this.state.width * 0.8;
    } else {
      return this.state.width * 0.9;
    }
  };

  getHeightForModal = () => {
    if (window.innerHeight > 820) {
      return window.innerHeight * 0.55;
    } else {
      return window.innerHeight * 0.65;
    }
  };

  setCurrency = (value) => {
    this.setState({
      currency: value,
    });
  };

  setCrypto = (value) => {
    this.setState({
      crypto: value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="deposit-container">
          <div class="deposit-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={this.tryToGoBackStage}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              {this.state.stage == 1 ||
              this.state.stage == 2 ||
              this.state.stage == 3 ||
              this.state.stage == 4 ? (
                <div>
                  {" "}
                  <TotalBalance
                    account={this.state.account}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                  ></TotalBalance>
                  <div class="title fade-in-up">
                    {this.props.localizedText["deposit"]}
                  </div>
                  {this.state.stage == 1 ? (
                    <div>
                      {this.state.loadingCurrencies ? (
                        <div class="fade-in-up">
                          <Spinner className="loader loading-currencies" />
                        </div>
                      ) : (
                        <div>
                          <div class="section">
                            <div class="option-div">
                              <div class="sub-title">
                                {
                                  this.props.localizedText[
                                    "*please-choose-a-deposit-type-from-the-options-available-below"
                                  ]
                                }
                              </div>{" "}
                              <div class="deposit-list">
                                <div
                                  class="deposit-row-container"
                                  onClick={this.moveStage.bind(this, 2)}
                                >
                                  <div class="deposit-row">
                                    <div class="background"> </div>
                                    <div class="content">
                                      <div class="metadata-column">
                                        <RiBankFill class="icon" />
                                        <div class="text">
                                          <div class="option-name">
                                            {this.props.localizedText["bank"]}
                                          </div>
                                          <div class="option-description">
                                            {
                                              this.props.localizedText[
                                                "deposit-funds-directly-into-your-bank-account"
                                              ]
                                            }
                                          </div>
                                        </div>
                                      </div>

                                      <IoIosArrowForward class="arrow"></IoIosArrowForward>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="deposit-row-container"
                                  onClick={this.moveStage.bind(this, 3)}
                                >
                                  <div class="deposit-row">
                                    <div class="background"> </div>
                                    <div class="content">
                                      <div class="metadata-column">
                                        <SiBitcoinsv class="icon" />
                                        <div class="text">
                                          <div class="option-name">
                                            {this.props.localizedText["crypto"]}
                                          </div>
                                          <div class="option-description">
                                            {
                                              this.props.localizedText[
                                                "deposit-funds-using-your-crypto-wallet"
                                              ]
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <IoIosArrowForward class="arrow"></IoIosArrowForward>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="deposit-row-container"
                                  onClick={this.moveStage.bind(this, 4)}
                                >
                                  <div class="deposit-row">
                                    <div class="background"> </div>
                                    <div class="content">
                                      <div class="metadata-column">
                                        <FaMoneyBillWave class="icon" />
                                        <div class="text">
                                          <div class="option-name">
                                            {" "}
                                            {this.props.localizedText["cash"]}
                                          </div>
                                          <div class="option-description">
                                            {
                                              this.props.localizedText[
                                                "deposit-funds-using-cash"
                                              ]
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <IoIosArrowForward class="arrow"></IoIosArrowForward>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  ) : null}
                  {this.state.stage == 2 ? (
                    <DepositBank
                      changeGoBackNavBar={this.props.changeGoBackNavBar}
                      localizedText={this.props.localizedText}
                      listCurrenciesFiat={this.state.listCurrenciesFiat}
                      tryToGoBackStage={this.tryToGoBackStage}
                      setCurrency={this.setCurrency}
                    />
                  ) : null}{" "}
                  {this.state.stage == 3 ? (
                    <DepositCrypto
                      changeGoBackNavBar={this.props.changeGoBackNavBar}
                      localizedText={this.props.localizedText}
                      listCryptos={this.state.listCryptos}
                      tryToGoBackStage={this.tryToGoBackStage}
                      setCrypto={this.setCrypto}
                    />
                  ) : null}{" "}
                  {this.state.stage == 4 ? (
                    <DepositCash
                      changeGoBackNavBar={this.props.changeGoBackNavBar}
                      localizedText={this.props.localizedText}
                      listCurrenciesFiat={this.state.listCurrenciesFiat}
                      tryToGoBackStage={this.tryToGoBackStage}
                      setCurrency={this.setCurrency}
                    />
                  ) : null}{" "}
                </div>
              ) : null}{" "}
              {this.state.stage == "result" ? (
                <Result
                  success={this.state.resultSuccess}
                  secondButton={false}
                  title={
                    this.state.resultSuccess
                      ? this.props.localizedText["great-news!"]
                      : this.props.localizedText["something-went-wrong"]
                  }
                  message={this.props.localizedText[this.state.resultMessage]}
                  primaryButtonText={this.props.localizedText["continue"]}
                  primaryButtonFunction={utils.goToURL.bind(
                    this,
                    this.props,
                    "/dashboard"
                  )}
                ></Result>
              ) : null}
            </div>
            <div class="side-container">
              {this.state.stage == 2 && this.state.currency ? (
                <ConversionComponent
                  currencyFrom={this.state.currency}
                  currencyTo={"USD"}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></ConversionComponent>
              ) : null}
              {this.state.stage == 3 && this.state.crypto ? (
                <ConversionComponent
                  currencyFrom={this.state.crypto}
                  currencyTo={"USD"}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></ConversionComponent>
              ) : null}
              {this.state.stage == 4 && this.state.currency ? (
                <ConversionComponent
                  currencyFrom={this.state.currency}
                  currencyTo={"USD"}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></ConversionComponent>
              ) : null}
            </div>
          </div>
          <Modal
            show={this.state.showDepositModal}
            onHide={this.handleDepositCloseModal}
            className="deposit-modal"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <iframe
                width={this.getWidthForModal()}
                height={this.getHeightForModal()}
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src={this.state.depositURL}
              ></iframe>
            </Modal.Body>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Deposit);
