import axios from "axios";
import { utils } from "../utils/utils";

class WithdrawAPI {
  withdrawBank(
    portfolioName,
    currency,
    amount,
    accountType,
    accountNumber,
    bankID,
    note
  ) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_name: portfolioName,
      currency: currency,
      amount: amount,
      account_type: accountType,
      account_number: accountNumber,
      bank_id: Number(bankID),
      note: note,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/simple_funds_out_bank",
      requestBody,
      headers
    );
  }

  withdrawCash(portfolioName, currency, amount, note) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_name: portfolioName,
      currency: currency,
      amount: amount,
      note: note,
    };

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + "/payments/simple_funds_out_cash",
      requestBody,
      headers
    );
  }

  withdrawCrypto(porfolioId, walletAddress, cryptoToken, network, amount) {
    const token = JSON.parse(utils.getTokenFromStorage())["access_token"];
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const requestBody = {
      portfolio_id: porfolioId,
      target_wallet_address: walletAddress,
      token: cryptoToken,
      blockchain: network,
      qty: Number(amount),
      block: "1",
    };

    var endpoint = "/wallets/withdraw_crypto";

    return axios.post(
      process.env.REACT_APP_BASE_API_URL + endpoint,
      requestBody,
      headers
    );
  }
}

export const withdrawAPI = new WithdrawAPI();
