import { portfolioAPI } from "../../apis/portfolioAPI";
import { marketDataAPI } from "../../apis/marketDataAPI";

export default class TotalBalanceService {
  getDefaultPortfolio(setState, stopChecking) {
    portfolioAPI
      .getDefaultPortfolio()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("success", response);
          this.getPortfolioBalances(setState, response.data.name, stopChecking);
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getPortfolioBalances(setState, portfolioName, stopChecking) {
    portfolioAPI
      .getPortfolioBalances(portfolioName)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          var total = 0;
          var fiatRequests = [];
          var fiatResponses = [];
          var cryptoRequests = [];
          var cryptoResponses = [];
          var listSelectedCurrencies = [];
          for (var i = 0; i < response.data.length; i++) {
            if (
              response.data[i].fiat &&
              response.data[i].base_currency != "USD"
            ) {
              //conversion for fiat
              fiatRequests.push(response.data[i].base_currency);
              fiatResponses.push(
                marketDataAPI.getFiatConversion(
                  response.data[i].base_currency,
                  "USD",
                  response.data[i].qty
                )
              );
            } else if (!response.data[i].fiat) {
              //conversion for crypto
              cryptoRequests.push(response.data[i].base_currency);
              cryptoResponses.push(
                marketDataAPI.getProductStats24H(
                  response.data[i].base_currency,
                  "USD"
                )
              );
            } else {
              total = total + response.data[i].qty;
              if (response.data[i].fav) {
                listSelectedCurrencies.push({
                  data: response.data[i],
                  value_usd: null,
                });
              }
            }
          }

          Promise.allSettled([
            Promise.allSettled(fiatResponses),
            Promise.allSettled(cryptoResponses),
          ])
            .then(([fiatResponses, cryptoResponses]) => {
              for (var i = 0; i < fiatResponses.value.length; i++) {
                console.log("fiatResponses ", i, fiatResponses.value[i]);
                if (fiatResponses.value[i].status != "rejected") {
                  if (
                    JSON.parse(fiatResponses.value[i].value.data.payload).price
                  ) {
                    total =
                      total +
                      JSON.parse(fiatResponses.value[i].value.data.payload)
                        .price *
                        response.data.filter(
                          (element) => element.base_currency == fiatRequests[i]
                        )[0].qty;
                    if (
                      response.data.filter(
                        (element) => element.base_currency == fiatRequests[i]
                      )[0].fav
                    ) {
                      listSelectedCurrencies.push({
                        data: response.data.filter(
                          (element) => element.base_currency == fiatRequests[i]
                        )[0],
                        value_usd:
                          JSON.parse(fiatResponses.value[i].value.data.payload)
                            .price *
                          response.data.filter(
                            (element) =>
                              element.base_currency == fiatRequests[i]
                          )[0].qty,
                      });
                    }
                  }
                } else {
                  console.log("There was an error converting a currency");
                }
              }

              for (var i = 0; i < cryptoResponses.value.length; i++) {
                console.log("cryptoResponses", i, cryptoResponses.value[i]);
                if (cryptoResponses.value[i].status != "rejected") {
                  if (
                    JSON.parse(cryptoResponses.value[i].value.data.payload).last
                  ) {
                    total =
                      total +
                      JSON.parse(cryptoResponses.value[i].value.data.payload)
                        .last *
                        response.data.filter(
                          (element) =>
                            element.base_currency == cryptoRequests[i]
                        )[0].qty;
                    if (
                      response.data.filter(
                        (element) => element.base_currency == cryptoRequests[i]
                      )[0].fav
                    ) {
                      listSelectedCurrencies.push({
                        data: response.data.filter(
                          (element) =>
                            element.base_currency == cryptoRequests[i]
                        )[0],
                        value_usd:
                          JSON.parse(
                            cryptoResponses.value[i].value.data.payload
                          ).last *
                          response.data.filter(
                            (element) =>
                              element.base_currency == cryptoRequests[i]
                          )[0].qty,
                      });
                    }
                  }
                } else {
                  console.log("There was an error converting a currency");
                }
              }

              console.log("listSelectedCurrencies", listSelectedCurrencies);
              if (stopChecking != null) {
                stopChecking();
              }
              setState({
                loading: false,
                totalBalance: total,
                listSelectedCurrencies: listSelectedCurrencies,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }
}
