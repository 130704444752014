import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./withdrawBank.css";
import WithdrawBankService from "./withdrawBankService.jsx";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { Spinner } from "react-bootstrap";
import { utils } from "../../../utils/utils.jsx";
import ErrorMessageComponent from "../../errorMessage/errorMessage.jsx";
import ConversionLinkComponent from "../../conversionLink/conversionLink.jsx";

class WithdrawBank extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    listPaymentProviders: [],
    listFiatWithdraw: [],

    errorMessageType: "",

    currency: "",
    amount: "",
    accountType: "",
    accountNumber: "",
    bankID: "",
    note: "",

    code: "",
    authChoice: "",
    confirmPhoneMessage: "",

    withdrawCashResponse: {},

    loadingPaymentProviders: true,
    loadingCurrenciesWithdraw: true,
    loadingNetworks: true,
    loadingSent: false,

    invalidCurrency: false,
    invalidAmount: false,
    invalidAccountType: false,
    invalidAccountNumber: false,
    invalidBankID: false,
    invalidWalletAddress: false,
    invalidCode: false,

    resultSuccess: true,

    stage: 1,
  };

  service = new WithdrawBankService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.service.getClientConfig(this.setState.bind(this));
    this.service.getPaymentProvidersByCountry(this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.props.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.props.tryToGoBackStage
      )
    );

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
    }
  }

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  validateWithdrawBank = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.currency != "" &&
          this.state.amount != "" &&
          this.state.bankID != "" &&
          this.state.accountType != "" &&
          this.state.accountNumber != "" &&
          this.state.code != ""
        ) {
          if (this.state.amount != "" && this.state.currency != "") {
            if (
              this.state.amount >
              Number(
                this.props.listFiatWithdraw.filter(
                  (element) => element.base_currency == this.state.currency
                )[0].available_qty
              )
            ) {
              this.setMessages(
                "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
              );
              this.setState({
                invalidAmount: true,
              });
            } else if (
              this.state.currency == "COP" &&
              this.state.amount < 10000
            ) {
              this.setMessages("*minimum-withdraw-for-colombian-pesos");
              this.setState({
                invalidAmount: true,
              });
            } else {
              //Proceed with withdraw
              this.setState(
                {
                  invalidCurrency: false,
                  invalidAmount: false,
                  invalidBankID: false,
                  invalidAccountType: false,
                  invalidAccountNumber: false,

                  loadingSent: true,
                },
                () => {
                  if (this.state.authChoice == "Phone") {
                    this.service.validatePhone(
                      this.state.code,
                      this.setState.bind(this),
                      this.setMessages.bind(this),
                      this.doWithdrawBank.bind(this)
                    );
                  } else {
                    this.service.validateAuth(
                      this.state.code,
                      this.setState.bind(this),
                      this.setMessages.bind(this),
                      this.doWithdrawBank.bind(this)
                    );
                  }
                }
              );
            }
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCurrency: this.state.currency == "",
            invalidAmount: this.state.amount == "",
            invalidBankID: this.state.bankID == "",
            invalidAccountType: this.state.accountType == "",
            invalidAccountNumber: this.state.accountNumber == "",
            invalidCode: this.state.code == "",
          });
        }
      }
    );
  };

  doWithdrawBank = () => {
    this.service.withdrawBank(
      this.state.currency,
      this.state.amount,
      this.state.accountType,
      this.state.accountNumber,
      this.state.bankID,
      this.state.note,
      this.props.goToResult.bind(this)
    );
  };

  handChangeForInvalidAmountFiat = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.setCurrency(this.state.currency);
        if (this.state.amount != "" && this.state.currency != "") {
          //utils.handleChangeForInvalidAmount(event, this.state.listFiatWithdraw, this.state.amount, this.state.currency, this.setMessages.bind(this, "withdraw-error-invalid-amount"), this.setState.bind(this))
          if (
            this.state.amount >
            Number(
              this.props.listFiatWithdraw.filter(
                (element) => element.base_currency == this.state.currency
              )[0].available_qty
            )
          ) {
            this.setMessages(
              "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
            );
            this.setState({
              invalidAmount: true,
            });
          } else {
            this.setState({
              invalidAmount: false,
              errorMessageType: "",
            });
          }
        }
      }
    );
  };

  confirmPhone = () => {
    this.setState(
      {
        loadingPhone: true,
        code: "",
        invalidCode: false,
        errorMessageType: "",
      },
      () => {
        this.service.confirmPhone(this.setState.bind(this));
      }
    );
  };

  getResultMessage = () => {
    if (this.state.resultSuccess) {
      return this.props.localizedText["you-have-successfully-withdrawn"];
    } else {
      return this.props.localizedText[
        "unfortunately,-there-was-a-problem-with-the-withdraw"
      ];
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loadingPaymentProviders ? (
          <div class="fade-in-up">
            <Spinner className="loader loading-currencies" />
          </div>
        ) : (
          <div class="withdraw-bank-div">
            <ConversionLinkComponent
              width={this.state.width}
              message={this.props.localizedText["see-conversion-rate"]}
              currencyFrom={this.state.currency}
              currencyTo={"USD"}
              region={this.props.region}
              localizedText={this.props.localizedText}
            />
            <div class="section">
              <div class="left-input left-input-no-height">
                <div class="background"></div>
                <div class="content">
                  <div class="form-div">
                    <Form.Group className="mb-3">
                      <Form.Label className={"input-label-inside-div"}>
                        {this.props.localizedText["currency"]}
                      </Form.Label>
                      <InputGroup className="mb-3 input-field">
                        <Form.Select
                          variant="outline-secondary"
                          onChange={this.handChangeForInvalidAmountFiat}
                          name="currency"
                          isInvalid={this.state.invalidCurrency}
                          style={{
                            width: "100%",
                            flex: "0 1 auto",
                            textAlign: "start",
                          }}
                          disabled={this.state.loadingSent}
                        >
                          <option></option>
                          {this.props.listFiatWithdraw.map((currency) => (
                            <option value={currency.base_currency}>
                              {currency.name +
                                " (" +
                                currency.base_currency +
                                ")" +
                                " (" +
                                utils.roundDecimals(
                                  currency.base_currency,
                                  currency.available_qty
                                ) +
                                ")"}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div class="form-div">
                    <Form.Group className="mb-3">
                      <Form.Label className="input-label-inside-div">
                        {this.props.localizedText["amount"]}
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          className="input-text no-background-input-inside"
                          type="number"
                          name="amount"
                          value={this.state.amount}
                          isInvalid={this.state.invalidAmount}
                          onChange={this.handChangeForInvalidAmountFiat}
                          disabled={this.state.loadingSent}
                        />{" "}
                        <InputGroup.Text
                          className={
                            this.state.invalidAmount
                              ? "inside-input-symbol input-symbol-danger"
                              : "inside-input-symbol"
                          }
                        >
                          {this.state.currency}
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>{" "}
                  </div>{" "}
                  <div class="form-div">
                    <Form.Group className="mb-3">
                      <Form.Label className={"input-label-inside-div"}>
                        {this.props.localizedText["bank"]}
                      </Form.Label>
                      <InputGroup className="mb-3 input-field">
                        <Form.Select
                          variant="outline-secondary"
                          onChange={(e) =>
                            utils.handleChange(e, this.setState.bind(this))
                          }
                          name="bankID"
                          isInvalid={this.state.invalidBankID}
                          style={{
                            width: "100%",
                            flex: "0 1 auto",
                            textAlign: "start",
                          }}
                          disabled={this.state.loadingSent}
                        >
                          <option></option>
                          {this.state.listPaymentProviders.map(
                            (paymentProvider) => (
                              <option value={paymentProvider.provider_id}>
                                {paymentProvider.provider_name}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div class="form-div">
                    <Form.Group className="mb-3">
                      <Form.Label className="input-label-inside-div">
                        {this.props.localizedText["code"]}
                      </Form.Label>
                      <FormControl
                        name="code"
                        value={this.state.code}
                        onChange={(e) =>
                          utils.handleChange(e, this.setState.bind(this))
                        }
                        isInvalid={this.state.invalidCode}
                        className="input-field"
                        disabled={this.state.loadingSent}
                        autoComplete="off"
                      />
                    </Form.Group>
                    {this.state.authChoice == "Phone" ? (
                      <div class="auth-div">
                        {
                          this.props.localizedText[
                            "a-text-message-with-a-8-digit-verification-code-was-just-sent-to"
                          ]
                        }
                        &nbsp;
                        {this.state.message + " "}
                        <b onClick={this.confirmPhone}>
                          {" "}
                          {this.props.localizedText["resend-code"]}
                        </b>
                      </div>
                    ) : (
                      <div class="auth-div">
                        {
                          this.props.localizedText[
                            "please-enter-the-code-from-your-authenticator-app"
                          ]
                        }
                        <br />
                        <b
                          onClick={utils.goToURL.bind(
                            this,
                            this.props,
                            "/dashboard/settings"
                          )}
                        >
                          {this.props.localizedText["change-auth-method"]}
                        </b>
                      </div>
                    )}{" "}
                  </div>
                </div>
              </div>{" "}
              <div class="right-div">
                <div class="value-input">
                  <Form.Group className="mb-3">
                    <Form.Label className={"input-label-outside-div"}>
                      {this.props.localizedText["account-type"]}
                    </Form.Label>
                    <InputGroup className="mb-3 input-field">
                      <Form.Select
                        variant="outline-secondary"
                        onChange={(e) =>
                          utils.handleChange(e, this.setState.bind(this))
                        }
                        name="accountType"
                        isInvalid={this.state.invalidAccountType}
                        style={{
                          width: "100%",
                          flex: "0 1 auto",
                          textAlign: "start",
                        }}
                        disabled={this.state.loadingSent}
                      >
                        <option></option>
                        <option value={"SAVINGS"}>
                          {this.props.localizedText["savings"]}
                        </option>
                        <option value={"CHECKING"}>
                          {this.props.localizedText["checking"]}
                        </option>
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="input-label-outside-div">
                      {this.props.localizedText["account-number"]}
                    </Form.Label>
                    <FormControl
                      name="accountNumber"
                      value={this.state.email}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      isInvalid={this.state.invalidAccountNumber}
                      className="input-field"
                      disabled={this.state.loadingSent}
                    />
                  </Form.Group>
                  <InputGroup className="input-group">
                    <Form.Label className="input-label-outside-div">
                      {this.props.localizedText["note-(optional)"]}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="note"
                      value={this.state.note}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      className="text-area"
                      disabled={this.state.loadingSent}
                    />
                  </InputGroup>
                </div>{" "}
              </div>
            </div>{" "}
            <div>
              <ErrorMessageComponent
                errorMessage={
                  this.props.localizedText[this.state.errorMessageType]
                }
              ></ErrorMessageComponent>
              <div class="buttons-div fade-in-up">
                <Button
                  className="primary-button outside-primary-button"
                  onClick={this.validateWithdrawBank}
                  disabled={this.state.loadingSent}
                >
                  {this.state.loadingSent ? (
                    <Spinner className="loader-send-outside-div-button"></Spinner>
                  ) : null}
                  {this.props.localizedText["withdraw"]}
                </Button>

                <Button
                  className="secondary-button outside-secondary-button"
                  onClick={this.props.tryToGoBackStage}
                  disabled={this.state.loadingSent}
                >
                  {this.props.localizedText["cancel"]}
                </Button>
              </div>
            </div>{" "}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(WithdrawBank);
