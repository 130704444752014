import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./withdrawCrypto.css";
import WithdrawCryptoService from "./withdrawCryptoService.jsx";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { Spinner } from "react-bootstrap";
import { utils } from "../../../utils/utils.jsx";
import ErrorMessageComponent from "../../errorMessage/errorMessage.jsx";
import ConversionLinkComponent from "../../conversionLink/conversionLink.jsx";

class WithdrawCrypto extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    listCryptoWithdraw: [],
    listNetworks: [],

    errorMessageType: "",

    amount: "",
    walletAddress: "",
    token: "",
    network: {},

    code: "",
    authChoice: "",
    confirmPhoneMessage: "",

    withdrawCashResponse: {},

    loadingNetworks: true,
    loadingSent: false,

    invalidAmount: false,
    invalidWalletAddress: false,
    invalidToken: false,
    invalidNetwork: false,
    invalidCode: false,

    resultSuccess: true,

    stage: 1,
  };

  service = new WithdrawCryptoService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.service.getClientConfig(this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.tryToGoBackStage
      )
    );

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
    }
  }

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  validateWithdrawCrypto = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.token != "" &&
          this.state.amount != "" &&
          this.state.network != "" &&
          this.state.walletAddress != "" &&
          this.state.code != ""
        ) {
          if (this.state.amount != "" && this.state.token != "") {
            if (
              this.state.amount >
              this.props.listCryptoWithdraw.filter(
                (element) => element.base_currency == this.state.token
              )[0].available_qty
            ) {
              this.setMessages(
                "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
              );
              this.setState({
                invalidAmount: true,
              });
            } else {
              //Proceed with withdraw

              this.setState(
                {
                  invalidToken: false,
                  invalidAmount: false,
                  invalidNetwork: false,
                  invalidWalletAddress: false,

                  loadingSent: true,
                },
                () => {
                  if (this.state.authChoice == "Phone") {
                    this.service.validatePhone(
                      this.state.code,
                      this.setState.bind(this),
                      this.setMessages.bind(this),
                      this.doWithdrawCrypto.bind(this)
                    );
                  } else {
                    this.service.validateAuth(
                      this.state.code,
                      this.setState.bind(this),
                      this.setMessages.bind(this),
                      this.doWithdrawCrypto.bind(this)
                    );
                  }
                }
              );
            }
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidToken: this.state.token == "",
            invalidAmount: this.state.amount == "",
            invalidNetwork: this.state.network == {},
            invalidWalletAddress: this.state.walletAddress == "",
            invalidCode: this.state.code == "",
          });
        }
      }
    );
  };

  doWithdrawCrypto = () => {
    this.service.withdrawCrypto(
      this.props.listCryptoWithdraw.filter(
        (element) => element.base_currency == this.state.token
      )[0].portfolio_id,
      this.state.walletAddress,
      this.state.token,
      this.state.network,
      this.state.amount,
      this.props.goToResult.bind(this)
    );
  };

  handleChangeForCrypto = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (this.state.crypto != value) {
      if (value != "" && value != null) {
        this.setState(
          {
            [name]: value,
          },
          () => {
            this.props.setCrypto(this.state.token);
            this.setState({
              loadingNetworks: true,
              loadingWallet: false,
              qrcodeBase64: "",
            });
            this.service.getNetworks(
              this.state.token,
              this.setState.bind(this),
              this.setMessages.bind(this)
            );
            //utils.handleChangeForInvalidAmount(event, this.state.listCryptoWithdraw, this.state.amount, this.state.token, this.setMessages.bind(this, "withdraw-error-invalid-amount"), this.setState.bind(this))
            if (this.state.amount != "" && this.state.token != "") {
              if (
                this.state.amount >
                this.props.listCryptoWithdraw.filter(
                  (element) => element.base_currency == this.state.token
                )[0].qty
              ) {
                this.setMessages(
                  "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
                );
                this.setState({
                  invalidAmount: true,
                });
              } else {
                this.setState({
                  invalidAmount: false,
                  errorMessageType: "",
                });
              }
            }
          }
        );
      } else {
        if (value == "") {
          this.setState({
            network: "",
            loadingNetworks: false,
            loadingWallet: false,
            qrcodeBase64: "",
          });
        }

        this.setState({
          [name]: value,
        });
      }
    }
  };

  handChangeForInvalidAmountCrypto = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (this.state.amount != "" && this.state.token != "") {
          if (
            this.state.amount >
            this.props.listCryptoWithdraw.filter(
              (element) => element.base_currency == this.state.token
            )[0].qty
          ) {
            this.setMessages(
              "*please-adjust-the-entered-amount.-it-exceeds-your-current-holdings"
            );
            this.setState({
              invalidAmount: true,
            });
          } else {
            this.setState({
              invalidAmount: false,
              errorMessageType: "",
            });
          }
        }
      }
    );
  };

  confirmPhone = () => {
    this.setState(
      {
        loadingPhone: true,
        code: "",
        invalidCode: false,
        errorMessageType: "",
      },
      () => {
        this.service.confirmPhone(this.setState.bind(this));
      }
    );
  };

  getResultMessage = () => {
    if (this.state.resultSuccess) {
      return this.props.localizedText["you-have-successfully-withdrawn"];
    } else {
      return this.props.localizedText[
        "unfortunately,-there-was-a-problem-with-the-withdraw"
      ];
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="withdraw-crypto-div">
          <ConversionLinkComponent
            width={this.state.width}
            message={this.props.localizedText["see-conversion-rate"]}
            currencyFrom={this.state.token}
            currencyTo={"USD"}
            region={this.props.region}
            localizedText={this.props.localizedText}
          />
          <div class="section">
            <div class="left-input left-input-no-height">
              <div class="background"></div>
              <div class="content">
                <div class="form-div">
                  <Form.Group className="mb-3">
                    <Form.Label className={"input-label-inside-div"}>
                      {this.props.localizedText["token"]}
                    </Form.Label>
                    <InputGroup className="mb-3 input-field">
                      <Form.Select
                        variant="outline-secondary"
                        onChange={(e) => this.handleChangeForCrypto(e)}
                        name="token"
                        isInvalid={this.state.invalidToken}
                        style={{
                          width: "100%",
                          flex: "0 1 auto",
                          textAlign: "start",
                        }}
                        disabled={this.state.loadingSent}
                      >
                        <option></option>
                        {this.props.listCryptoWithdraw.map((currency) => (
                          <option value={currency.base_currency}>
                            {currency.base_currency +
                              " (" +
                              utils.roundDecimals(
                                currency.base_currency,
                                currency.qty
                              ) +
                              ")"}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div class="form-div">
                  <Form.Group className="mb-3">
                    <Form.Label className="input-label-inside-div">
                      {this.props.localizedText["amount"]}
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="input-text no-background-input-inside"
                        type="number"
                        name="amount"
                        value={this.state.amount}
                        isInvalid={this.state.invalidAmount}
                        onChange={this.handChangeForInvalidAmountCrypto}
                        disabled={this.state.loadingSent}
                      />{" "}
                      <InputGroup.Text
                        className={
                          this.state.invalidAmount
                            ? "inside-input-symbol input-symbol-danger"
                            : "inside-input-symbol"
                        }
                      >
                        {this.state.token}
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>{" "}
                </div>{" "}
                <div class="form-div">
                  <Form.Group className="mb-3">
                    <Form.Label className="input-label-inside-div">
                      {this.props.localizedText["code"]}
                    </Form.Label>
                    <FormControl
                      name="code"
                      value={this.state.code}
                      onChange={(e) =>
                        utils.handleChange(e, this.setState.bind(this))
                      }
                      isInvalid={this.state.invalidCode}
                      className="input-field"
                      disabled={this.state.loadingSent}
                      autoComplete="off"
                    />
                  </Form.Group>
                  {this.state.authChoice == "Phone" ? (
                    <div class="auth-div">
                      {
                        this.props.localizedText[
                          "a-text-message-with-a-8-digit-verification-code-was-just-sent-to"
                        ]
                      }
                      &nbsp;
                      {this.state.message + " "}
                      <b onClick={this.confirmPhone}>
                        {" "}
                        {this.props.localizedText["resend-code"]}
                      </b>
                    </div>
                  ) : (
                    <div class="auth-div">
                      {
                        this.props.localizedText[
                          "please-enter-the-code-from-your-authenticator-app"
                        ]
                      }
                      <br />
                      <b
                        onClick={utils.goToURL.bind(
                          this,
                          this.props,
                          "/dashboard/settings"
                        )}
                      >
                        {this.props.localizedText["change-auth-method"]}
                      </b>
                    </div>
                  )}{" "}
                </div>
              </div>
            </div>{" "}
            <div class="right-div">
              <div class="value-input">
                {" "}
                {this.state.token != "" ? (
                  <div>
                    {this.state.loadingNetworks ? (
                      <div class="fade-in-up">
                        <Spinner className="loading-conversions" />
                      </div>
                    ) : (
                      <div>
                        <Form.Group className="mb-3 fade-in-up">
                          <Form.Label className={"input-label-outside-div"}>
                            {this.props.localizedText["network"]}
                          </Form.Label>
                          <InputGroup className="mb-3 input-field">
                            <Form.Select
                              variant="outline-secondary"
                              onChange={(e) =>
                                utils.handleChange(e, this.setState.bind(this))
                              }
                              name="network"
                              style={{
                                width: "100%",
                                flex: "0 1 auto",
                                textAlign: "start",
                              }}
                              disabled={
                                this.state.loadingSent ||
                                this.state.listNetworks.length <= 1
                              }
                            >
                              {this.state.listNetworks.length == 1 ? (
                                <option
                                  value={this.state.listNetworks[0].blockchain}
                                >
                                  {this.state.listNetworks[0].description}
                                </option>
                              ) : null}
                              <option></option>
                              {this.state.listNetworks.map((network) => (
                                <option value={network.blockchain}>
                                  {network.description}
                                </option>
                              ))}
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3 fade-in-up">
                          <Form.Label className="input-label-outside-div">
                            {this.props.localizedText["wallet-address"]}
                          </Form.Label>
                          <FormControl
                            name="walletAddress"
                            value={this.state.walletAddress}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidWalletAddress}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </Form.Group>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>{" "}
            </div>
          </div>{" "}
          <div>
            <ErrorMessageComponent
              errorMessage={
                this.props.localizedText[this.state.errorMessageType]
              }
            ></ErrorMessageComponent>
            <div class="buttons-div fade-in-up">
              <Button
                className="primary-button outside-primary-button"
                onClick={this.validateWithdrawCrypto}
                disabled={this.state.loadingSent}
              >
                {this.state.loadingSent ? (
                  <Spinner className="loader-send-outside-div-button"></Spinner>
                ) : null}
                {this.props.localizedText["withdraw"]}
              </Button>

              <Button
                className="secondary-button outside-secondary-button"
                onClick={this.props.tryToGoBackStage}
                disabled={this.state.loadingSent}
              >
                {this.props.localizedText["cancel"]}
              </Button>
            </div>
          </div>{" "}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(WithdrawCrypto);
