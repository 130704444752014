import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./newVendorPaymentMethod.css";
import NewVendorPaymentMethodService from "./newVendorPaymentMethodService";
import GoBackButton from "../../../components/go-back-button/goBackButton";
import Result from "../../../components/result/result";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { utils } from "../../../utils/utils";
import ErrorMessageComponent from "../../../components/errorMessage/errorMessage";
import { RiBankFill } from "react-icons/ri";
import { MdOutlineError } from "react-icons/md";
import NoticeComponent from "../../../components/notice/notice";
import Modal from "react-bootstrap/Modal";

class NewVendorPaymentMethod extends Component {
  state = {
    width: window.innerWidth,

    listPaymentMethods: [],
    selectedPaymentMethod: {},
    paymentAccountName: "",
    addPaymentRequest: {},

    newPaymentMethod: "",
    errorMessageType: "",

    validListPaymentMethods: [],
    invalidListPaymentMethods: [],
    invalidPaymentAccountName: false,

    searchedInfo: null,
    searchedInfoSuccess: false,

    loadingPaymentMethods: true,
    loadingInfo: false,
    loadingSent: false,

    showWireUSNotice: false,

    stage: 1,
    goBackUrl: null,
  };

  service = new NewVendorPaymentMethodService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.checkProps();

    this.service.getPaymentMethods(this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      null
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        null
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
    }
  }

  checkProps = () => {
    if (
      this.props.location.megastate &&
      this.props.location.megastate.client_vendor_id
    ) {
    } else {
      utils.goToURL(this.props, "/dashboard/payment/vendors/");
    }
  };

  handleSelectChange = (event) => {
    const target = event.target;
    const value = target.value;

    if (
      this.state.selectedPaymentMethod != this.state.listPaymentMethods[value]
    ) {
      this.setState(
        {
          selectedPaymentMethod: {},
        },
        () => {
          if (value != "") {
            this.setState({
              selectedPaymentMethod: this.state.listPaymentMethods[value],
              addPaymentRequest: {},
              paymentAccountName: "",
              invalidPaymentAccountName: false,
              invalidListPaymentMethods: [],
              searchedInfo: null,
              searchedInfoSuccess: false,
              errorMessageType: "",
            });
          }
        }
      );
    }
  };

  handleDynamicChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    var auxObject = this.state.addPaymentRequest;
    auxObject[name] = value;

    this.setState({
      addPaymentRequest: auxObject,
    });
  };

  handleDynamicChangeSearch = (event, type, lengthMin, lengthMax) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    var auxObject = this.state.addPaymentRequest;
    auxObject[name] = value;

    this.setState(
      {
        addPaymentRequest: auxObject,
      },
      () => {
        console.log(
          "this.state.addPaymentRequest",
          this.state.addPaymentRequest
        );
        if (
          lengthMax == null
            ? this.state.addPaymentRequest[name].length == lengthMin
            : this.state.addPaymentRequest[name].length >= lengthMin &&
              this.state.addPaymentRequest[name].length <= lengthMax
        ) {
          this.setState(
            {
              loadingInfo: true,
            },
            () => {
              if (type == "routing_number") {
                console.log(
                  "validating routing number",
                  this.state.selectedPaymentMethod.in_out_method_type
                );
                this.service.validateRoutingNumber(
                  this.state.addPaymentRequest[name],
                  this.state.selectedPaymentMethod.in_out_method_type == "ach"
                    ? "ach"
                    : "wire",
                  this.setState.bind(this)
                );
              } else {
                console.log("validating swift code");
                this.service.validateSwiftCode(
                  this.state.addPaymentRequest[name],
                  this.state.selectedPaymentMethod.in_out_method_type,
                  this.setState.bind(this)
                );
              }
            }
          );
        } else {
          this.setState({
            searchedInfo: null,
            searchedInfoSuccess: false,
          });
        }
      }
    );
  };

  getDynamicPaymentAccountTitle = (key, description) => {
    if (key == "bank_wire_intl") {
      return this.props.localizedText[
        "bank-same-day-outbound-wire-international"
      ];
    } else if (key == "venmo") {
      return this.props.localizedText["venmo"];
    } else if (key == "paypal") {
      return this.props.localizedText["paypal"];
    } else if (key == "crypto") {
      return this.props.localizedText["crypto-currency"];
    } else if (key == "card") {
      return this.props.localizedText["credit-debit-card"];
    } else if (key == "cheque") {
      return this.props.localizedText["physical-check"];
    } else if (key == "ach") {
      return this.props.localizedText["ach-transfer"];
    } else if (key == "bank_wire_us") {
      return this.props.localizedText["bank-same-day-outbound-wire-us"];
    } else {
      return description;
    }
  };

  getDynamicLabel = (key, description) => {
    if (key == "acount_name") {
      return this.props.localizedText["name-on-the-account"];
    } else if (key == "country") {
      return this.props.localizedText["country-of-bank-account"];
    } else if (key == "recipient_name") {
      return this.props.localizedText[
        "name-cheque-will-me-written-to.-<=-40-characters"
      ];
    } else if (key == "address_1") {
      return this.props.localizedText["full-address"];
    } else if (key == "address_2") {
      return this.props.localizedText["address-line-2"];
    } else if (key == "city") {
      return this.props.localizedText["city"];
    } else if (key == "state") {
      return this.props.localizedText[
        "for-us-addressed-the-2-letter-state-abbreviation.-for-international-addresses-the-county,-providence,-or-region"
      ];
    } else if (key == "postal_code") {
      return this.props.localizedText["zip-or-postal-code"];
    } else if (key == "phone_number") {
      return this.props.localizedText["phone-number"];
    } else if (key == "routing_number") {
      return this.props.localizedText["routing-number"];
    } else if (key == "account_type") {
      return this.props.localizedText["checking-or-saving"];
    } else if (key == "account_class") {
      return this.props.localizedText["business-or-personal"];
    } else if (key == "account_number") {
      return this.props.localizedText["bank-account-number"];
    } else if (key == "swift_bic_code") {
      return this.props.localizedText["swift-/-bic-code"];
    } else if (key == "venmo_user_name") {
      return this.props.localizedText["venmo-@username"];
    } else if (key == "phone") {
      return this.props.localizedText["phone-registered-at-venmo"];
    } else if (key == "email") {
      return this.props.localizedText["email-registered-ay-venmo"];
    } else if (key == "paypal_user_name") {
      return this.props.localizedText["paypal-@username"];
    } else if (key == "token_symbol") {
      return this.props.localizedText["token-symbol-name"];
    } else if (key == "wallet_address") {
      return this.props.localizedText["wallet-address-to-deposit-to"];
    } else if (key == "wallet_label") {
      return this.props.localizedText["wallet-internal-name"];
    } else if (key == "name_on_card") {
      return this.props.localizedText["name-of-bank-account"];
    } else if (key == "beneficiary_name") {
      return this.props.localizedText["beneficiary-name"];
    } else {
      return description;
    }
  };

  goToPayVendor = () => {
    this.props.history.push({
      pathname: "/dashboard/payment/vendors/pay",
      megastate: {
        from: true,
        paymentMethod: this.state.newPaymentMethod,
      },
    });
  };

  addPaymentMethod = () => {
    const mandatoryFields =
      this.state.selectedPaymentMethod.fields.fields.filter(
        (field) => field.mandatory
      );

    var requestIsOrHasEmpty = false;
    for (let i = 0; i < mandatoryFields.length; i++) {
      if (
        Object.keys(this.state.addPaymentRequest).includes(
          mandatoryFields[i].name
        )
      ) {
      } else {
        requestIsOrHasEmpty = true;
      }
    }

    if (!requestIsOrHasEmpty) {
      for (
        let j = 0;
        j < Object.keys(this.state.addPaymentRequest).length;
        j++
      ) {
        if (
          this.state.addPaymentRequest[
            Object.keys(this.state.addPaymentRequest)[j]
          ] == ""
        ) {
          requestIsOrHasEmpty = true;
        }
      }
    }

    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.paymentAccountName != "" && !requestIsOrHasEmpty) {
          this.setState(
            {
              invalidPaymentAccountName: false,
              invalidListPaymentMethods: [],
              loadingSent: true,
            },
            () => {
              this.service.createVendorPaymentAccount(
                this.props.location.megastate.client_vendor_id,
                this.state,
                this.setState.bind(this),
                this.setMessages.bind(this)
              );
            }
          );
        } else {
          const invalidListPaymentMethods =
            this.state.selectedPaymentMethod.fields.fields.map(
              (field, index) => {
                if (field.mandatory) {
                  return {
                    index,
                    value: !this.state.addPaymentRequest[field.name],
                  };
                }
                return {
                  index,
                  value: false,
                };
              }
            );

          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidPaymentAccountName: this.state.paymentAccountName === "",
            invalidListPaymentMethods,
          });
        }
      }
    );
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  handleClose = () => {
    this.setState({
      showWireUSNotice: false,
    });
  };

  getInfoDiv = (type) => {
    if (type == "bank_wire_us") {
      return (
        <div class="payment-method-info-row fade-in-up">
          <div class="payment-method-info-sub-title">
            {this.props.localizedText["bank-same-day-outbound-wire-us"]}
          </div>
          <div class="payment-method-info-description">
            {
              this.props.localizedText[
                "fast-domestic-wire-transfer-within-the-us"
              ]
            }
          </div>
        </div>
      );
    } else if (type == "bank_wire_intl") {
      return (
        <div class="payment-method-info-row fade-in-up">
          <div class="payment-method-info-sub-title">
            {
              this.props.localizedText[
                "bank-same-day-outbound-wire-international"
              ]
            }
          </div>
          <div class="payment-method-info-description">
            {
              this.props.localizedText[
                "expedited-wire-transfer-service-for-international-accounts"
              ]
            }
          </div>
        </div>
      );
    } else if (type == "venmo") {
      return (
        <div class="payment-method-info-row fade-in-up">
          <div class="payment-method-info-sub-title">
            {" "}
            {this.props.localizedText["venmo"]}
          </div>
          <div class="payment-method-info-description">
            {
              this.props.localizedText[
                "convenient-mobile-payment-service-linked-to-your-phone"
              ]
            }
          </div>
        </div>
      );
    } else if (type == "paypal") {
      return (
        <div class="payment-method-info-row fade-in-up">
          <div class="payment-method-info-sub-title">
            {this.props.localizedText["paypal"]}
          </div>
          <div class="payment-method-info-description">
            {
              this.props.localizedText[
                "secure-online-payment-platform-for-sending-money"
              ]
            }
          </div>
        </div>
      );
    } else if (type == "crypto") {
      return (
        <div class="payment-method-info-row fade-in-up">
          <div class="payment-method-info-sub-title">
            {" "}
            {this.props.localizedText["crypto-currency"]}
          </div>
          <div class="payment-method-info-description">
            {
              this.props.localizedText[
                "transfer-digital-assets-securely-to-any"
              ]
            }
          </div>
        </div>
      );
    } else if (type == "card") {
      return (
        <div class="payment-method-info-row fade-in-up">
          <div class="payment-method-info-sub-title">
            {this.props.localizedText["credit-debit-card"]}
          </div>
          <div class="payment-method-info-description">
            {
              this.props.localizedText[
                "use-your-credit-card-for-quick-and-easy-payments"
              ]
            }
          </div>
        </div>
      );
    } else if (type == "cheque") {
      return (
        <div class="payment-method-info-row fade-in-up">
          <div class="payment-method-info-sub-title">
            {" "}
            {this.props.localizedText["physical-check"]}
          </div>
          <div class="payment-method-info-description">
            {
              this.props.localizedText[
                "traditional-payment-method-where-a-physical-check-is-mailed"
              ]
            }
          </div>
        </div>
      );
    } else if (type == "ach") {
      return (
        <div class="payment-method-info-row fade-in-up">
          <div class="payment-method-info-sub-title">
            {" "}
            {this.props.localizedText["ach-transfer"]}
          </div>
          <div class="payment-method-info-description">
            {
              this.props.localizedText[
                "automated-clearing-house-transfer-for-electronic-bank-to-bank"
              ]
            }
          </div>
        </div>
      );
    }
  };

  dynamicInputFields = ({ paymentMethod }) => (
    <div class="payment-method-input-div fade-in-up">
      <Form.Group className="mb-3">
        <Form.Label className="input-label-outside-div">
          {this.props.localizedText["account-name"]}
        </Form.Label>
        <FormControl
          name="paymentAccountName"
          value={this.state.paymentAccountName}
          onChange={(e) => utils.handleChange(e, this.setState.bind(this))}
          isInvalid={this.state.invalidPaymentAccountName}
          className="input-field"
          disabled={this.state.loadingSent}
          autoComplete="off"
        />
      </Form.Group>

      {paymentMethod.fields.fields.map((field, index) => (
        <div>
          {field.name != "routing_number" && field.name != "swift_bic_code" ? (
            <Form.Group className="mb-3">
              <Form.Label className="input-label-outside-div">
                {this.getDynamicLabel(field.name, field.description) +
                  (field.mandatory
                    ? ""
                    : " " + this.props.localizedText["(optional)"])}
              </Form.Label>
              {field.values == "ANY" ? (
                <FormControl
                  name={field.name}
                  value={
                    this.state.addPaymentRequest[field.name] != null
                      ? this.state.addPaymentRequest[field.name]
                      : ""
                  }
                  onChange={this.handleDynamicChange}
                  isInvalid={
                    this.state.invalidListPaymentMethods[index] &&
                    this.state.invalidListPaymentMethods[index]["value"]
                  }
                  className="input-field"
                  disabled={this.state.loadingSent}
                />
              ) : (
                <InputGroup className="mb-3 input-field">
                  <Form.Select
                    variant="outline-secondary"
                    name={field.name}
                    onChange={this.handleDynamicChange}
                    style={{
                      width: "100%",
                      flex: "0 1 auto",
                      textAlign: "start",
                    }}
                    isInvalid={
                      this.state.invalidListPaymentMethods[index] &&
                      this.state.invalidListPaymentMethods[index]["value"]
                    }
                    disabled={this.state.loadingSent}
                  >
                    <option></option>
                    {field.values.split("|").map((name) => (
                      <option value={name}>{name}</option>
                    ))}
                  </Form.Select>
                </InputGroup>
              )}
            </Form.Group>
          ) : null}
          {field.name == "routing_number" ? (
            <div class="input-search-div mb-3">
              <Form.Group className="search-info-input">
                <Form.Label className="input-label-outside-div">
                  {this.getDynamicLabel(field.name, field.description) +
                    (field.mandatory
                      ? ""
                      : " " + this.props.localizedText["(optional)"])}
                </Form.Label>
                <FormControl
                  name={field.name}
                  value={
                    this.state.addPaymentRequest[field.name] != null
                      ? this.state.addPaymentRequest[field.name]
                      : ""
                  }
                  onChange={(e) => {
                    this.handleDynamicChangeSearch(e, field.name, 9);
                  }}
                  isValid={
                    this.state.searchedInfo != null &&
                    this.state.searchedInfoSuccess
                  }
                  isInvalid={
                    this.state.searchedInfo != null &&
                    !this.state.searchedInfoSuccess
                  }
                  className="input-field"
                  disabled={this.state.loadingSent}
                  autoComplete="off"
                />
              </Form.Group>{" "}
              {!this.state.loadingInfo &&
              this.state.searchedInfo != null &&
              this.state.searchedInfoSuccess ? (
                <div class="search-info-div">
                  <RiBankFill className="search-result-icon" />
                  <div class="search-result-text-div">
                    <div class="search-result-text">
                      {this.state.searchedInfo.paymentType}
                    </div>
                    <div class="search-result-text">
                      {this.state.searchedInfo.name}
                    </div>
                    <div class="search-result-text">
                      {this.state.searchedInfo.city},&nbsp;
                      {this.state.searchedInfo.state}
                    </div>{" "}
                  </div>
                </div>
              ) : null}
              {!this.state.loadingInfo &&
              this.state.searchedInfo != null &&
              !this.state.searchedInfoSuccess ? (
                <div class="search-info-div">
                  <MdOutlineError className="search-result-icon" />{" "}
                  <div class="search-result-text">
                    {this.props.localizedText["no-bank-information-found"]}
                  </div>
                </div>
              ) : null}
              {this.state.loadingInfo ? (
                <div class="loader-search-info">
                  <Spinner className="loader-small-outside" />
                </div>
              ) : null}
            </div>
          ) : null}
          {field.name == "swift_bic_code" ? (
            <div class="input-search-div mb-3">
              <Form.Group className="search-info-input">
                <Form.Label className="input-label-outside-div">
                  {this.getDynamicLabel(field.name, field.description) +
                    (field.mandatory
                      ? ""
                      : " " + this.props.localizedText["(optional)"])}
                </Form.Label>
                <FormControl
                  name={field.name}
                  value={
                    this.state.addPaymentRequest[field.name] != null
                      ? this.state.addPaymentRequest[field.name]
                      : ""
                  }
                  onChange={(e) => {
                    this.handleDynamicChangeSearch(e, field.name, 8, 11);
                  }}
                  isValid={
                    this.state.searchedInfo != null &&
                    this.state.searchedInfoSuccess
                  }
                  isInvalid={
                    this.state.searchedInfo != null &&
                    !this.state.searchedInfoSuccess
                  }
                  className="input-field"
                  disabled={this.state.loadingSent}
                  autoComplete="off"
                />
              </Form.Group>{" "}
              {!this.state.loadingInfo &&
              this.state.searchedInfo != null &&
              this.state.searchedInfoSuccess ? (
                <div class="search-info-div">
                  <RiBankFill className="search-result-icon" />
                  <div class="search-result-text-div">
                    <div class="search-result-text">
                      {this.state.searchedInfo.bank_name}
                    </div>
                    <div class="search-result-text">
                      {this.state.searchedInfo.city}
                    </div>
                    <div class="search-result-text">
                      {this.state.searchedInfo.country}
                    </div>{" "}
                  </div>
                </div>
              ) : null}
              {!this.state.loadingInfo &&
              this.state.searchedInfo != null &&
              !this.state.searchedInfoSuccess ? (
                <div class="search-info-div">
                  <MdOutlineError className="search-result-icon" />{" "}
                  <div class="search-result-text">
                    {this.props.localizedText["no-bank-information-found"]}
                  </div>
                </div>
              ) : null}
              {this.state.loadingInfo ? (
                <div class="loader-search-info">
                  <Spinner className="loader-small-outside" />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ))}

      {this.state.selectedPaymentMethod.in_out_method_type ==
        "bank_wire_intl" && this.state.width <= 1200 ? (
        <NoticeComponent
          title={this.props.localizedText["important-notice"]}
          message={
            this.props.localizedText["if-your-bank-account-is-in-the-us"]
          }
        ></NoticeComponent>
      ) : null}

      <ErrorMessageComponent
        errorMessage={this.props.localizedText[this.state.errorMessageType]}
      ></ErrorMessageComponent>
      <div class="buttons-div">
        <Button
          className="primary-button outside-primary-button"
          onClick={this.addPaymentMethod}
          disabled={this.state.loadingSent}
        >
          {this.state.loadingSent ? (
            <Spinner className="loader-send-outside-div-button"></Spinner>
          ) : null}
          {this.props.localizedText["create"]}
        </Button>

        <Button
          className="secondary-button outside-secondary-button"
          onClick={utils.goBack.bind(this, this.props)}
        >
          {this.props.localizedText["cancel"]}
        </Button>
      </div>
    </div>
  );

  render() {
    return (
      <React.Fragment>
        <div class="payment-methods-container">
          <div class="payment-methods-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
              ></GoBackButton>
            </div>
            <div class="middle-container">
              {this.state.stage === 1 ? (
                <div class="payment-methods-padding-div">
                  <div class="main-title">
                    {this.props.localizedText["new-payment-method"]}
                  </div>
                  {this.state.loadingPaymentMethods ? (
                    <Spinner className="loader"></Spinner>
                  ) : (
                    <div class="fade-in-up">
                      <Form.Group className="mb-3">
                        <InputGroup className="mb-3 input-field">
                          <Form.Select
                            variant="outline-secondary"
                            onChange={this.handleSelectChange}
                            name="selectedPaymentMethod"
                            style={{
                              width: "100%",
                              flex: "0 1 auto",
                              textAlign: "start",
                            }}
                            disabled={this.state.loadingSent}
                          >
                            <option hidden value="">
                              {
                                this.props.localizedText[
                                  "please-select-a-value"
                                ]
                              }
                            </option>

                            {this.state.listPaymentMethods.map(
                              (paymentMethod, index) => (
                                <option value={index}>
                                  {this.getDynamicPaymentAccountTitle(
                                    paymentMethod.in_out_method_type,
                                    paymentMethod.description
                                  )}
                                </option>
                              )
                            )}
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                      {process.env.REACT_APP_ENV != "prod" &&
                      this.state.width <= 1200 ? (
                        <div class="payment-method-info-div">
                          {this.getInfoDiv(
                            this.state.selectedPaymentMethod.in_out_method_type
                          )}
                        </div>
                      ) : null}
                      {Object.keys(this.state.selectedPaymentMethod).length >
                      0 ? (
                        <this.dynamicInputFields
                          paymentMethod={this.state.selectedPaymentMethod}
                        ></this.dynamicInputFields>
                      ) : null}
                    </div>
                  )}
                </div>
              ) : (
                <div class="payment-methods-padding-div">
                  <Result
                    success={true}
                    secondButton={true}
                    title={this.props.localizedText["great-news!"]}
                    message={
                      this.props.localizedText[
                        "you-have-successfully-added-a-payment-method"
                      ]
                    }
                    primaryButtonText={this.props.localizedText["pay"]}
                    primaryButtonFunction={this.goToPayVendor}
                    secondaryButtonText={
                      this.props.localizedText["go-to-vendors"]
                    }
                    secondaryButtonFunction={utils.goToURL.bind(
                      this,
                      this.props,
                      "/dashboard/payment/vendors"
                    )}
                  ></Result>
                </div>
              )}
            </div>
            <div class="side-container">
              {process.env.REACT_APP_ENV != "prod" ? (
                <div class="payment-method-info-div">
                  {this.getInfoDiv(
                    this.state.selectedPaymentMethod.in_out_method_type
                  )}
                </div>
              ) : null}

              {this.state.selectedPaymentMethod.in_out_method_type ==
              "bank_wire_intl" ? (
                <NoticeComponent
                  title={this.props.localizedText["important-notice"]}
                  message={
                    this.props.localizedText[
                      "if-your-bank-account-is-in-the-us"
                    ]
                  }
                ></NoticeComponent>
              ) : null}
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showWireUSNotice}
          onHide={this.handleClose}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["important-notice"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.localizedText["if-your-bank-account-is-in-the-us"]}
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <Button
              className="outside-secondary-button"
              onClick={this.handleClose}
            >
              {this.props.localizedText["close"]}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(NewVendorPaymentMethod);
