import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./assetDetail.css";
import AssetDetailService from "./assetDetailService";
import { FaPrint } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { utils } from "../../utils/utils";
import GoBackButton from "../../components/go-back-button/goBackButton";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { ImSearch } from "react-icons/im";
import Modal from "react-bootstrap/Modal";
import ReceiptComponent from "../../components/receipt/receipt";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import PrintPDFComponent from "../../components/printPDF/printPDF";

class AssetDetail extends Component {
  state = {
    isMobileWidth: false,

    chartIndexPicked: 2,
    chartOption: [
      {
        name: "1D",
        length: 1,
        "length-in-granularity": 24,
        granularity: "HOUR",
        "number-points": 24,
      },
      {
        name: "5D",
        length: 5,
        "length-in-granularity": 120,
        granularity: "HOUR",
        "number-points": 40,
      },
      {
        name: "10D",
        length: 10,
        "length-in-granularity": 240,
        granularity: "HOUR",
        "number-points": 40,
      },
      {
        name: "1M",
        length: 30,
        "length-in-granularity": 30,
        granularity: "DAY",
        "number-points": 30,
      },
      {
        name: "2M",
        length: 60,
        "length-in-granularity": 60,
        granularity: "DAY",
        "number-points": 30,
      },
      {
        name: "3M",
        length: 90,
        "length-in-granularity": 90,
        granularity: "DAY",
        "number-points": 45,
      },
    ],

    currencyData: {},
    chartData: [],

    listTransactions: [],
    listTransactionsShowed: [],

    selectedDivIndex: null,
    selectedTransaction: {},

    showModalTransactionDetails: false,

    loadingCurrencyData: true,
    loadingHistoricalCandles: true,
    loadingTransactions: true,
    loadingDownload: false,
  };

  service = new AssetDetailService();

  constructor(props) {
    super(props);

    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard/portfolio"
    );
    window.addEventListener("resize", this.checkWindowWidth);

    this.service.getDefaultPortfolio(
      this.setState.bind(this),
      this.props.match.params.asset
    );
    this.service.getHistoricalCandles(
      this.setState.bind(this),
      this.props.match.params.asset,
      this.state.chartOption[this.state.chartIndexPicked].granularity,
      Date.now() / 1000 -
        86400 * this.state.chartOption[this.state.chartIndexPicked].length, //Multiplying days to get in seconds
      Date.now() / 1000,
      2
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
    }
  }

  componentWillUnmount() {}

  vstyle = getComputedStyle(document.body);

  chartStyles = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return utils.roundDecimals("USD", tooltipItem.parsed.y);
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          display: "block",
        },
        grid: {
          display: "block",
        },
        border: {
          display: "block",
          color: this.vstyle.getPropertyValue("--outside-highlight-color"),
        },
      },
      y: {
        ticks: {
          display: "block",
        },
        grid: {
          display: "block",
        },
        border: {
          display: "block",
          color: this.vstyle.getPropertyValue("--outside-highlight-color"),
        },
      },
    },
    animation: { duration: 1000, y: { from: 25 }, x: { from: false } },
    responsive: true,
    maintainAspectRatio: false,
  };

  checkGoBackNavBar = () => {
    this.props.changeGoBackNavBar(false, null);
  };

  getDataset = (chartData, lengthInGranularity, numberOfPoints) => {
    //numberOfPointswill always be so that intervals are the same, so that it can be proportional
    let values = [];
    let pointIndex = 0;
    // chartData.length is length in days
    const adder = lengthInGranularity / numberOfPoints;
    const timeInterval =
      this.state.chartOption[this.state.chartIndexPicked]["granularity"] ==
      "HOUR"
        ? 3600
        : 86400;
    let labels = [];
    let timeStampInterval = null;
    let label = null;

    for (let i = 0; i < numberOfPoints; i++) {
      values.push(chartData[pointIndex][4]);
      pointIndex = pointIndex + adder;

      //for labels
      timeStampInterval = Date.now() / 1000 - timeInterval * i;
      if (timeInterval == 3600) {
        label = new Date(timeStampInterval * 1000)
          .toLocaleString("en-US")
          .slice(10);
      } else {
        label = new Date(timeStampInterval * 1000)
          .toLocaleString("en-US")
          .split(",")[0];
      }
      labels.push(label);
    }
    console.log("values", values);
    let chartDataWithStyles = {
      labels: labels,
      datasets: [
        {
          label: this.props.match.params.asset,
          data: values,
          backgroundColor: this.vstyle.getPropertyValue(
            "--outside-highlight-color"
          ),
          borderColor: this.vstyle.getPropertyValue(
            "--outside-highlight-color"
          ),
          pointRadius: 0,
        },
      ],
    };

    return chartDataWithStyles;
  };

  handleChartLengthChange = (index) => {
    if (this.state.chartIndexPicked != index) {
      this.setState(
        {
          chartData: [],
          loadingHistoricalCandles: true,
        },
        () => {
          console.log(
            "this.state.chartOption[index].length",
            this.state.chartOption[index].length
          );
          this.service.getHistoricalCandles(
            this.setState.bind(this),
            this.props.match.params.asset,
            this.state.chartOption[index].granularity,
            Date.now() / 1000 - 86400 * this.state.chartOption[index].length, //Multiplying days to get in seconds
            Date.now() / 1000,
            index
          );
        }
      );
    }
  };

  getTitle = (card) => {
    console.log("card", card);
    if (
      card["transaction_type"] === "DEPOSIT" &&
      !card["transaction_subtype"]
    ) {
      return this.props.localizedText["deposit"];
    } else if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === "RECEIVED_FROM_PEER"
    ) {
      return this.props.localizedText["received-from-peer"];
    } else if (
      card["transaction_type"] === "HOLD" &&
      !card["transaction_subtype"]
    ) {
      return this.props.localizedText["hold"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      !card["transaction_subtype"]
    ) {
      return this.props.localizedText["withdraw"];
    } else if (
      (card["transaction_type"] === "WITHDRAW" ||
        card["transaction_type"] === "HOLD") &&
      card["transaction_subtype"] === "PAY_VENDOR"
    ) {
      return this.props.localizedText["pay-vendor"];
    } else if (
      (card["transaction_type"] === "WITHDRAW" ||
        card["transaction_type"] === "HOLD") &&
      card["transaction_subtype"] === "SEND_TO_PEER"
    ) {
      return this.props.localizedText["send-to-peer"];
    } else if (card["transaction_type"] === "BUY") {
      return this.props.localizedText["buy"];
    } else if (card["transaction_type"] === "SELL") {
      return this.props.localizedText["sell"];
    } else {
      return card["transaction_type"];
    }
  };

  getDescription = (card) => {
    console.log("card", card);
    if (
      (card["transaction_type"] === "WITHDRAW" &&
        card["transaction_subtype"] === null) ||
      (card["transaction_type"] === "HOLD" &&
        card["transaction_subtype"] === null)
    ) {
      return this.props.localizedText["withdraw-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === "SEND_TO_PEER"
    ) {
      return this.props.localizedText["sent-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "WITHDRAW" &&
      card["transaction_subtype"] === "PAY_VENDOR"
    ) {
      return this.props.localizedText["paid-to-"] + card["to"];
    } else if (
      card["transaction_type"] === "BUY" &&
      card["transaction_subtype"] === null
    ) {
      return (
        this.props.localizedText["buy-"] +
        card["qty"] +
        card["base_currency"] +
        this.props.localizedText["-at-"] +
        card["price"]
      );
    } else if (
      card["transaction_type"] === "DEPOSIT" &&
      card["transaction_subtype"] === "RECEIVED_FROM_PEER"
    ) {
      return this.props.localizedText["received-from-"] + card["from"];
    } else if (
      card["transaction_type"] === "SELL" &&
      card["transaction_subtype"] === null
    ) {
      return (
        this.props.localizedText["sell-"] +
        card["qty"] +
        card["base_currency"] +
        this.props.localizedText["-at-"] +
        card["price"]
      );
    } else {
      return card["description"];
    }
  };

  getStatus = (card) => {
    console.log("card", card);
    if (card["transaction_type"] === "HOLD") {
      return this.props.localizedText["processing"];
    } else {
      return this.props.localizedText["completed"];
    }
  };

  openTransactionDetails = (payment, index) => {
    this.setState({
      selectedDivIndex: index,
      selectedTransaction: payment,
      showModalTransactionDetails: true,
    });
  };

  handleCloseTransactionDetails = () => {
    this.setState({
      selectedDivIndex: null,
      showModalTransactionDetails: false,
    });
  };

  getReceiptProps = () => {
    let data = [
      {
        title: this.props.localizedText["transfer-summary"],
        data: [
          {
            name: this.props.localizedText["transaction-id"],
            value: this.state.selectedTransaction.transaction_id,
            type: "normal",
          },
          {
            name: this.props.localizedText["transaction-type"],
            value: this.state.selectedTransaction.transaction_type,
            type: "normal",
          },
          {
            name: this.props.localizedText["transaction-sub-type"],
            value: this.state.selectedTransaction.transaction_subtype
              ? this.state.selectedTransaction.transaction_subtype
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["from"],
            value: this.state.selectedTransaction.from,
            type: "normal",
          },
          {
            name: this.props.localizedText["to"],
            value: this.state.selectedTransaction.to,
            type: "normal",
          },
          {
            currency: this.state.selectedTransaction.base_currency,
            name: this.props.localizedText["amount"],
            value: this.state.selectedTransaction.qty,
            type: "amount",
          },
          {
            name: this.props.localizedText["date"],
            value: this.state.selectedTransaction.creation_time,
            type: "date",
          },
          {
            name: this.props.localizedText["fee-amount"],
            value: this.state.selectedTransaction.fee_amount
              ? this.state.selectedTransaction.fee_amount
              : "-",
            type: "normal",
          },
          {
            name: this.props.localizedText["description"],
            value: this.state.selectedTransaction.description,
            type: "normal",
          },
        ],
      },
    ];

    return data;
  };

  downloadPDF = () => {
    this.setState(
      {
        loadingDownload: true,
      },
      () => {
        utils.generatePDF(
          this.state.selectedTransaction.transaction_type +
            "-" +
            this.state.selectedTransaction.transaction_id,
          this.setState.bind(this)
        );
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="asset-detail-container">
          <div class="asset-detail-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard/portfolio/assets"}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              <div class="asset-detail-div">
                <div class="asset-detail-balance fade-in-up">
                  <div class="metadata-column">
                    <div class="image"></div>
                    <div class="text">
                      <div class="title">{this.props.match.params.asset}</div>
                      <div class="abbreviation">
                        {this.props.match.params.asset}
                      </div>
                    </div>
                  </div>
                  {!this.state.loadingCurrencyData ? (
                    <div class="balance-column">
                      <div class="value">
                        {this.props.localizedText["amount-in-usd"] +
                          ":" +
                          " $" +
                          utils.roundDecimals(
                            "USD",
                            this.state.currencyData["value-usd"]
                          )}
                      </div>
                      <div class="percentage">
                        {this.props.localizedText["quantity:"] +
                          " " +
                          utils.roundDecimals(
                            this.props.match.params.asset,
                            this.state.currencyData["qty"]
                          )}
                      </div>
                    </div>
                  ) : (
                    <Spinner className="loader-total-balance"></Spinner>
                  )}
                  <div class="buttons-column">
                    <Link
                      to="/dashboard/deposit"
                      className="link-button-inside left-button"
                    >
                      {this.props.localizedText["deposit"]}
                    </Link>
                    <Link
                      to="/dashboard/withdraw"
                      className="link-button-inside left-button"
                    >
                      {this.props.localizedText["withdraw"]}
                    </Link>
                    <Link
                      to="/dashboard/conversion"
                      className="link-button-inside"
                    >
                      {this.props.localizedText["convert"]}
                    </Link>
                  </div>
                </div>
                {this.props.match.params.asset != "USD" ? (
                  <div>
                    {" "}
                    <div class="time-length-div fade-in-up">
                      {" "}
                      <div class="filter-div">
                        <div class="compare-option-div"></div>
                        <div class="chart-length-option-div">
                          {this.state.chartOption.map((option, index) => (
                            <div
                              class={
                                this.state.chartOption[
                                  this.state.chartIndexPicked
                                ]["name"] == option.name
                                  ? "option option-picked"
                                  : "option"
                              }
                              onClick={this.handleChartLengthChange.bind(
                                this,
                                index
                              )}
                            >
                              {option.name}
                            </div>
                          ))}
                        </div>
                      </div>
                      {this.state.loadingHistoricalCandles ? (
                        <Spinner className="loader-small-outside "></Spinner>
                      ) : null}
                    </div>
                    <div class="chart-div">
                      {this.state.chartData.length > 0 ? (
                        <Line
                          options={this.chartStyles}
                          data={this.getDataset(
                            this.state.chartData,
                            this.state.chartOption[this.state.chartIndexPicked][
                              "length-in-granularity"
                            ],
                            this.state.chartOption[this.state.chartIndexPicked][
                              "number-points"
                            ]
                          )}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}

                <div class="filter-search-div fade-in-up">
                  <InputGroup className="search-bar">
                    <ImSearch class="search-icon"></ImSearch>
                    <FormControl
                      onChange={(e) => {
                        utils.editSearchTermDefault(
                          e,
                          this.setState.bind(this),
                          this.state.listTransactions,
                          "listTransactionsShowed",
                          ["transaction_type"]
                        );
                      }}
                      id="search-bar-entities"
                      placeholder={
                        this.props.localizedText["search-transactions"]
                      }
                    />
                  </InputGroup>
                </div>
                {!this.state.loadingTransactions ? (
                  <div class="transactions-div fade-in-up">
                    {" "}
                    {this.state.listTransactionsShowed.map((card, index) => (
                      <div
                        class={
                          this.state.selectedDivIndex === index
                            ? "card-portfolio selected-card"
                            : "card-portfolio"
                        }
                        onClick={this.openTransactionDetails.bind(
                          this,
                          card,
                          index
                        )}
                      >
                        <div class="metadata-column">
                          <div class="text">
                            <div class="title">{this.getTitle(card)}</div>
                            <div class="date">
                              {utils.formatDateUSWithTime(
                                card["creation_time"]
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="status-column">{this.getStatus(card)}</div>
                        <div class="description-column">
                          {this.getDescription(card)}
                        </div>
                        <div class="amount-column">
                          <div
                            class={
                              card["qty"] > 0
                                ? "amount"
                                : "amount amount-danger"
                            }
                          >
                            {utils.roundDecimals(
                              card["base_currency"],
                              card["qty"]
                            )}
                            &nbsp;
                            {card["base_currency"]}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div class="fade-in-up">
                    <Spinner className="loader" />
                  </div>
                )}
              </div>
            </div>
            <div class="side-container"></div>
          </div>
        </div>

        <Modal
          show={this.state.showModalTransactionDetails}
          onHide={this.handleCloseTransactionDetails}
          className="primary-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.localizedText["transaction-details"]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="detail-container transaction-history-detail">
              {Object.keys(this.state.selectedTransaction).length > 0 ? (
                <ReceiptComponent
                  data={this.getReceiptProps()}
                ></ReceiptComponent>
              ) : null}
              <div class="icons-row">
                {this.state.loadingDownload ? (
                  <div class="loader-icon">
                    <Spinner className="loader-download left-icon"></Spinner>
                  </div>
                ) : (
                  <IoMdDownload class="left-icon" onClick={this.downloadPDF} />
                )}
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <FaPrint class="right-icon" onClick={handlePrint} />
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>{" "}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div class="payment-detail-footer">
              {this.props.localizedText["if-you-need-help"]}
              <br />
              {this.props.localizedText["go-to-our-"]}
              <a href="https://www.blox.global/contact/">
                {this.props.localizedText["resolution-center"]}
              </a>{" "}
              {
                this.props.localizedText[
                  "for-help-with-this-transaction,-to-settle-a-dispute-or-open-a-claim"
                ]
              }
            </div>
          </Modal.Footer>
        </Modal>

        <div id="hidden-div" style={{ display: "none" }}>
          <div
            class="detail-container-print"
            ref={(el) => (this.componentRef = el)}
            id="pdf"
          >
            {Object.keys(this.state.selectedTransaction).length > 0 ? (
              <PrintPDFComponent
                theme={this.props.theme}
                title={this.props.localizedText["transaction-details"]}
                receiptData={this.getReceiptProps()}
                selectedTransaction={this.state.selectedTransaction}
                text={this.props.localizedText["powered-by-blox"]}
              ></PrintPDFComponent>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AssetDetail);
