import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./home.css";
import TotalBalance from "../../components/total-balance/totalBalance";
import SearchPages from "../../components/search-pages/searchPages";
import { FaWallet } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { TbArrowsRightLeft } from "react-icons/tb";
import { BsFillSendFill } from "react-icons/bs";
import { HiIdentification, HiPhone, HiWallet } from "react-icons/hi2";
import { BiMoneyWithdraw } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import SideNotificationsDiv from "../../components/side-notifications/sideNotificationsDiv";
import { utils } from "../../utils/utils.jsx";
import { Link } from "react-router-dom";

class Home extends Component {
  state = {
    isMobileWidth: false,
    name: null,
    account: {},
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      false,
      null
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        false,
        null
      )
    );

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    } else {
      //ComponentDidUpdateWill take care of it
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
    }
  }

  checkGoBackNavBar = () => {
    this.props.changeGoBackNavBar(false, null);
  };

  render() {
    return (
      <React.Fragment>
        <div class="home-container ">
          <div class="home-body">
            <div class="side-container">
              {!this.state.isMobileWidth ? (
                <SideNotificationsDiv
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></SideNotificationsDiv>
              ) : null}
            </div>
            <div class="middle-container container-padding">
              <TotalBalance
                region={this.props.region}
                localizedText={this.props.localizedText}
                account={this.state.account}
              ></TotalBalance>

              <div class="hub-container">
                <div class="hub-row">
                  <div class="title">{this.props.localizedText["pay"]}</div>

                  <div class="hub-buttons-div">
                    <Link to="/dashboard/payment-options" class="hub-card">
                      <div class="text">
                        <div class="title">
                          <BsFillSendFill className="icon"></BsFillSendFill>
                          {this.props.localizedText["pay"]}
                        </div>
                        <div class="description">
                          {
                            this.props.localizedText[
                              "send-payments-to-vendors-or-in-app-users"
                            ]
                          }
                        </div>
                      </div>

                      <div class="image image-src-2"></div>
                    </Link>
                    <Link to="/dashboard/deposit" class="hub-card">
                      <div class="text">
                        <div class="title">
                          <FaWallet className="icon"></FaWallet>
                          {this.props.localizedText["deposit"]}
                        </div>
                        <div class="description">
                          {
                            this.props.localizedText[
                              "deposit-funds-to-your-wallet"
                            ]
                          }
                        </div>
                      </div>

                      <div class="image image-src-1"></div>
                    </Link>
                    <Link to="/dashboard/withdraw" class="hub-card">
                      <div class="text">
                        <div class="title">
                          <BiMoneyWithdraw className="icon"></BiMoneyWithdraw>
                          {this.props.localizedText["withdraw"]}
                        </div>
                        <div class="description">
                          {
                            this.props.localizedText[
                              "withdraw-funds-from-your-wallet"
                            ]
                          }
                        </div>
                      </div>

                      <div class="image image-src-1"></div>
                    </Link>

                    <Link to="/dashboard/pay-me" class="hub-card">
                      <div class="text">
                        <div class="title">
                          <GiReceiveMoney className="icon"></GiReceiveMoney>
                          {this.props.localizedText["pay-me"]}
                        </div>
                        <div class="description">
                          {" "}
                          {this.props.localizedText["send-payment-request"]}
                        </div>
                      </div>

                      <div class="image image-src-1"></div>
                    </Link>

                    <Link to="/dashboard/portfolio/assets" class="hub-card">
                      <div class="text">
                        <div class="title">
                          <HiWallet className="icon"></HiWallet>
                          {this.props.localizedText["portfolio"]}
                        </div>
                        <div class="description">
                          {
                            this.props.localizedText[
                              "see-the-total-balance-of-your-assets"
                            ]
                          }
                        </div>
                      </div>

                      <div class="image image-src-1"></div>
                    </Link>

                    <Link to="/dashboard/conversion" class="hub-card">
                      <div class="text">
                        <div class="title">
                          <TbArrowsRightLeft className="icon"></TbArrowsRightLeft>
                          {this.props.localizedText["convert"]}
                        </div>
                        <div class="description">
                          {
                            this.props.localizedText[
                              "see-our-rates-and-convert-assets-"
                            ]
                          }
                        </div>
                      </div>

                      <div class="image image-src-1"></div>
                    </Link>
                  </div>
                </div>
                <div class="hub-row">
                  <div class="title">
                    {this.props.localizedText["settings"]}
                  </div>
                  <div class="hub-buttons-div">
                    <Link to="/dashboard/settings" class="hub-card">
                      <div class="text">
                        <div class="title">
                          <IoMdSettings className="icon" />
                          {this.props.localizedText["settings"]}
                        </div>
                        <div class="description">
                          {
                            this.props.localizedText[
                              "change-the-settings-of-your-account"
                            ]
                          }
                        </div>
                      </div>

                      <div class="image image-src-1"></div>
                    </Link>
                    {this.props.phoneVerified != null &&
                    !this.props.phoneVerified ? (
                      <Link
                        to="/dashboard/settings/verify-phone"
                        class="hub-card"
                      >
                        <div class="text">
                          <div class="title">
                            <HiPhone className="icon" />
                            {this.props.localizedText["verify-phone"]}
                          </div>
                          <div class="description">
                            {
                              this.props.localizedText[
                                "phone-verification-required-for-transactions"
                              ]
                            }
                          </div>
                        </div>

                        <div class="image image-src-1"></div>
                      </Link>
                    ) : null}

                    {this.props.emailVerified != null &&
                    !this.props.emailVerified ? (
                      <Link
                        to="/dashboard/settings/verify-email"
                        class="hub-card"
                      >
                        <div class="text">
                          <div class="title">
                            <MdEmail className="icon" />
                            {this.props.localizedText["verify-email"]}
                          </div>
                          <div class="description">
                            {
                              this.props.localizedText[
                                "email-verification-required-for-transactions"
                              ]
                            }
                          </div>
                        </div>

                        <div class="image image-src-1"></div>
                      </Link>
                    ) : null}

                    {this.props.idVerified != null && !this.props.idVerified ? (
                      <Link
                        to="/dashboard/settings/id-verification"
                        class="hub-card"
                      >
                        <div class="text">
                          <div class="title">
                            <HiIdentification className="icon" />
                            {this.props.localizedText["verify-id"]}
                          </div>
                          <div class="description">
                            {
                              this.props.localizedText[
                                "id-verification-required-for-transactions"
                              ]
                            }
                          </div>
                        </div>

                        <div class="image image-src-1"></div>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div class="side-container">
              <SearchPages
                region={this.props.region}
                localizedText={this.props.localizedText}
              ></SearchPages>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
