import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./changePassword.css";
import ChangePasswordService from "./changePasswordService";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Spinner from "react-bootstrap/Spinner";
import Footer from "../../components/footer/footer";
import ThemeRegionDiv from "../../components/theme-region-div/themeRegionDiv";
import { utils } from "../../utils/utils.jsx";
import ErrorMessageComponent from "../../components/errorMessage/errorMessage.jsx";

class ChangePassword extends Component {
  state = {
    isMobileWidth: false,
    password: "",
    newPassword: "",
    retypeNewPassword: "",
    stage: 1,

    invalidPassword: false,
    invalidNewPassword: false,
    invalidRetypeNewPassword: false,

    resultSuccess: false,
    resultTitle: "",
    resultMessage: "",
    resultPrimaryButtonText: "",

    loadingSent: false,

    errorMessageType: "",
  };

  service = new ChangePasswordService();
  passwordRegex = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  );

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.checkProps();
    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
    }
  }

  checkWindowWidth = () => {
    this.windowSize = window.innerWidth;
    let flag = true;

    if (this.windowSize <= 1200) {
      flag = true;
    } else {
      flag = false;
    }

    this.setState({
      isMobileWidth: flag,
    });
  };

  checkProps = () => {
    console.log(this.props.location);
    if (
      this.props.location &&
      this.props.location.megastate &&
      this.props.location.megastate.fromSettings
    ) {
      this.setState({
        description:
          "*You will be logged out after making any changes to your password",
      });
    } else {
      if (
        this.props.location &&
        this.props.location.megastate &&
        this.props.location.megastate.from2fa
      ) {
        this.setState({
          description:
            "*You will need to change your password before continuing",
        });
      } else {
        this.setState({
          description:
            "*You will be logged out after making any changes to your password",
        });
      }
    }
  };

  handlePasswordChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let invalidVar = null;

    if (name == "newPassword") {
      invalidVar = "invalidNewPassword";
    }
    if (name == "retypeNewPassword") {
      invalidVar = "invalidRetypeNewPassword";
    }

    this.setState({
      [name]: value,
      [invalidVar]: !this.passwordRegex.test(value),
    });
    if (!this.passwordRegex.test(value)) {
      this.setMessages(
        "*password-must-be-minimum-8-digits-and-contain-at-least-one-upper-and-lowercase-letter,-a-number,-and-special-character"
      );
    }
  };

  cancelChangePassword = (url) => {
    if (
      this.props.location &&
      this.props.location.megastate &&
      this.props.location.megastate.fromSettings
    ) {
      utils.goToURL(this.props, "/dashboard/settings");
    } else {
      utils.goToURL(this.props, "/");
    }
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  changePassword = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (
          this.state.password != "" &&
          this.state.newPassword != "" &&
          this.state.retypeNewPassword != ""
        ) {
          if (this.state.newPassword === this.state.retypeNewPassword) {
            if (this.state.password != this.state.newPassword) {
              this.setState(
                {
                  invalidPassword: false,
                  invalidNewPassword: false,
                  invalidRetypeNewPassword: false,
                  loadingSent: true,
                },
                () => {
                  this.service.changePassword(
                    this.state,
                    this.setState.bind(this),
                    this.setMessages.bind(this),
                    this.goToDeleteAccountResultPage
                  );
                }
              );
            } else {
              this.setMessages(
                "*new-password-has-to-be-different-from-current-password"
              );
            }
          } else {
            this.setMessages("*passwords-don't-match");
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidPassword: this.state.password === "",
            invalidNewPassword: this.state.newPassword === "",
            invalidRetypeNewPassword: this.state.retypeNewPassword === "",
          });
        }
      }
    );
  };

  goToDeleteAccountResultPage = (value) => {
    if (value) {
      this.props.history.push({
        pathname: "/result",
        megastate: {
          success: value,
          type: "change-password",
          primaryButtonFunction: utils.goToURL.bind(this, this.props, "/"),
          secondButton: false,
        },
      });
    } else {
      //covered by errorMessage
    }
  };

  render() {
    return (
      <React.Fragment>
        <div class="login-container">
          <div class="login-2fa-div">
            <div class="register-title">
              {" "}
              {this.props.localizedText["change-password"]}
            </div>
            <div class="description">
              {
                this.props.localizedText[
                  "*you-will-be-logged-out-after-making-any-changes-to-your-password"
                ]
              }
            </div>
            <div class="login-form-div div-parent-flex-column">
              <Form.Group className="mb-3">
                <Form.Label
                  className={
                    this.state.isMobileWidth
                      ? "input-label-outside-div"
                      : "input-label-inside-div"
                  }
                >
                  {this.props.localizedText["current-password"]}
                </Form.Label>
                <FormControl
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={(e) =>
                    utils.handleChange(e, this.setState.bind(this))
                  }
                  isInvalid={this.state.invalidPassword}
                  className="input-field"
                  disabled={this.state.loadingSent}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label
                  className={
                    this.state.isMobileWidth
                      ? "input-label-outside-div"
                      : "input-label-inside-div"
                  }
                >
                  {this.props.localizedText["new-password"]}
                </Form.Label>
                <FormControl
                  type="password"
                  name="newPassword"
                  value={this.state.newPassword}
                  onChange={this.handlePasswordChange}
                  isInvalid={this.state.invalidNewPassword}
                  className="input-field"
                  disabled={this.state.loadingSent}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label
                  className={
                    this.state.isMobileWidth
                      ? "input-label-outside-div"
                      : "input-label-inside-div"
                  }
                >
                  {this.props.localizedText["retype-new-password"]}
                </Form.Label>
                <FormControl
                  type="password"
                  name="retypeNewPassword"
                  value={this.state.retypeNewPassword}
                  onChange={this.handlePasswordChange}
                  isInvalid={this.state.invalidRetypeNewPassword}
                  className="input-field"
                  disabled={this.state.loadingSent}
                />
              </Form.Group>
              <ErrorMessageComponent
                errorMessage={
                  this.props.localizedText[this.state.errorMessageType]
                }
              ></ErrorMessageComponent>
            </div>
            <div class="buttons-div">
              <Button
                className={
                  this.state.isMobileWidth
                    ? "primary-button outside-primary-button"
                    : "primary-button inside-primary-button"
                }
                onClick={this.changePassword}
                disabled={
                  this.state.loadingSent ||
                  this.state.invalidNewPassword ||
                  this.state.invalidRetypeNewPassword
                }
              >
                {this.state.loadingSent ? (
                  <Spinner
                    className={
                      this.state.isMobileWidth
                        ? "loader-send-outside-div-button"
                        : "loader-send-inside-div-button"
                    }
                  ></Spinner>
                ) : null}
                {this.props.localizedText["change"]}
              </Button>

              <Button
                className={
                  this.state.isMobileWidth
                    ? "secondary-button outside-secondary-button"
                    : "secondary-button inside-secondary-button"
                }
                onClick={this.cancelChangePassword}
              >
                {this.props.localizedText["cancel"]}
              </Button>
            </div>
          </div>
          <ThemeRegionDiv
            changeTheme={this.props.changeTheme}
            theme={this.props.theme}
            handleModalOpen={this.props.handleModalOpen}
            region={this.props.region}
          ></ThemeRegionDiv>
        </div>

        <Footer
          region={this.props.region}
          localizedText={this.props.localizedText}
          theme={this.props.theme}
        ></Footer>
      </React.Fragment>
    );
  }
}

export default withRouter(ChangePassword);
