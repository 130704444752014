import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./changePhone.css";
import ChangePhoneService from "./changePhoneService";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import GoBackButton from "../../../components/go-back-button/goBackButton";
import Result from "../../../components/result/result";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import { utils } from "../../../utils/utils.jsx";
import ErrorMessageComponent from "../../../components/errorMessage/errorMessage.jsx";

class ChangePhone extends Component {
  state = {
    isMobileWidth: false,
    countryCode: "",
    newPhoneNumber: "",
    stage: 1,

    invalidCountryCode: false,
    invalidNewPhoneNumber: false,

    resultSuccess: false,
    resultTitle: "",
    resultMessage: "",
    resultPrimaryButtonText: "",

    loadingCountries: true,
    loadingSent: false,

    listCountries: [], //region and country code

    errorMessageType: "",
  };

  service = new ChangePhoneService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.megastate &&
      this.props.location.megastate.phone
    ) {
    } else {
      utils.goToURL(this.props, "/dashboard/settings");
    }

    this.service.getCountries(this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard/settings"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard/settings"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  changePhone = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.countryCode != "" && this.state.newPhoneNumber != "") {
          this.setState(
            {
              invalidCountryCode: false,
              invalidNewPhoneNumber: false,
              loadingSent: true,
            },
            () => {
              this.service.changePhone(
                this.state,
                this.setState.bind(this),
                this.setMessages.bind(this),
                this.props.getClientConfig.bind(this)
              );
            }
          );
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidCountryCode: this.state.countryCode === "",
            invalidNewPhoneNumber: this.state.newPhoneNumber === "",
          });
        }
      }
    );
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="verify-info-container">
          <div class="side-container">
            <GoBackButton
              region={this.props.region}
              localizedText={this.props.localizedText}
              goBack={"/dashboard/settings"}
            ></GoBackButton>
          </div>
          <div class="middle-container container-padding">
            {this.state.stage === 1 ? (
              <div class="vendor-form-div">
                <div class="main-title">
                  {this.props.localizedText["change-phone-number"]}
                </div>

                {this.state.loadingCountries ? (
                  <Spinner className="loader"></Spinner>
                ) : (
                  <div class="fade-in-up">
                    <p>
                      {
                        this.props.localizedText[
                          "*you-will-need-to-verify-your-phone-number-after-making-any-changes-to-it"
                        ]
                      }
                    </p>
                    <div class="div-parent-flex-column">
                      <Form.Group className="mb-3">
                        <Form.Label className="input-label-outside-div">
                          {this.props.localizedText["phone-number"]}
                        </Form.Label>
                        <FormControl
                          type="text"
                          name="phone"
                          value={
                            this.props.location &&
                            this.props.location.megastate &&
                            this.props.location.megastate.phone
                              ? this.props.location.megastate.phone
                              : ""
                          }
                          disabled
                          className="input-field"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label
                          className={
                            this.state.isMobileWidth
                              ? "input-label-outside-div"
                              : "input-label-inside-div"
                          }
                        >
                          {this.props.localizedText["new-phone-number"]}
                        </Form.Label>
                        <InputGroup className="mb-3 input-field">
                          <Form.Select
                            variant="outline-secondary"
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            name="countryCode"
                            isInvalid={this.state.invalidCountryCode}
                            style={{
                              width: "5rem",
                              flex: "0 1 auto",
                              textAlign: "center",
                            }}
                            disabled={this.state.loadingSent}
                          >
                            <option></option>
                            {this.state.listCountries.map((country) => (
                              <option value={country.phone_area_code}>
                                {country.phone_area_code}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            type="tel"
                            name="newPhoneNumber"
                            value={this.state.newPhoneNumber}
                            onChange={(e) =>
                              utils.handleChange(e, this.setState.bind(this))
                            }
                            isInvalid={this.state.invalidNewPhoneNumber}
                            className="input-field"
                            disabled={this.state.loadingSent}
                          />
                        </InputGroup>
                      </Form.Group>
                      <ErrorMessageComponent
                        errorMessage={
                          this.props.localizedText[this.state.errorMessageType]
                        }
                      ></ErrorMessageComponent>
                      <div class="buttons-div">
                        <Button
                          className="primary-button outside-primary-button"
                          onClick={this.changePhone}
                          disabled={this.state.loadingSent}
                        >
                          {this.state.loadingSent ? (
                            <Spinner className="loader-send-outside-div-button"></Spinner>
                          ) : null}
                          {this.props.localizedText["change"]}
                        </Button>

                        <Button
                          className="secondary-button outside-secondary-button"
                          onClick={utils.goToURL.bind(
                            this,
                            this.props,
                            "/dashboard/settings"
                          )}
                        >
                          {this.props.localizedText["cancel"]}
                        </Button>
                      </div>
                      <div class="version-number">
                        {process.env.REACT_APP_VERSION}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {this.state.stage === 2 && !this.state.loadingCountries ? (
              <Result
                success={true}
                secondButton={true}
                title={this.props.localizedText["great-news!"]}
                message={
                  this.props.localizedText[
                    "the-phone-number-was-successfully-changed!"
                  ]
                }
                primaryButtonText={this.props.localizedText["verify-phone"]}
                primaryButtonFunction={utils.goToURL.bind(
                  this,
                  this.props,
                  "/dashboard/settings/verify-phone"
                )}
                secondaryButtonText={this.props.localizedText["settings"]}
                secondaryButtonFunction={utils.goToURL.bind(
                  this,
                  this.props,
                  "/dashboard/settings"
                )}
              ></Result>
            ) : null}
          </div>
          <div class="side-container"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ChangePhone);
