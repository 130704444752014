import { loginAPI } from "../../apis/loginAPI";

export default class TwoStepVerificationService {
  checkAuthType(setState, goToChangePassword) {
    loginAPI
      .getClientConfig()
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data) {
          if (response.data.required_2fa_2proceed == "SMS") {
            setState(
              {
                stageType: "phone",
                validationForAuth: false,
              },
              () => {
                this.confirmPhone(setState);
              }
            );
          }

          if (response.data.required_2fa_2proceed == "GOOGLE_AUTH") {
            setState({
              stageType: "auth",
            });
          }
          if (response.data.required_2fa_2proceed == "CHANGE_PASSWORD") {
            goToChangePassword();
          }
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  confirmPhone(setState) {
    loginAPI
      .confirmPhone()
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          //Success
          var mySubString = response.data.payload.substring(
            response.data.payload.indexOf("[") + 1,
            response.data.payload.lastIndexOf("]]")
          );

          setState(
            {
              message:
                "xxx-xxx-" +
                mySubString.slice(mySubString.length - 3, mySubString.length),
            },
            () => {
              setState({ loadingPhone: false });
            }
          );
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error.response);
      });
  }

  confirmEmail(setState) {
    loginAPI
      .confirmEmail()
      .then((response) => {
        if (response.status === 200) {
          //Success
          var arrays = response.data.payload.split(" ");
          var cutEmail = arrays[arrays.length - 1].slice(3);

          setState(
            {
              message: "***" + cutEmail,
            },
            () => {
              setState({ loadingEmail: false });
            }
          );
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  validatePhone(props, redirectCallback, state, setState, setMessages) {
    loginAPI
      .validatePhone(state.code)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          //Success

          this.getClientValidationStatus(
            props,
            redirectCallback,
            state,
            setState
          );
        } else {
          //Failure
          setMessages(
            "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateEmail(redirectCallback, code, setState, setMessages) {
    loginAPI
      .validateEmail(code)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          //Success
          redirectCallback("/dashboard");
        } else {
          //Failure
          setMessages(
            "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateAuthPhone(state, setState, setMessages) {
    loginAPI
      .validateAuthPhone(state.code)
      .then((response) => {
        console.log("SMS", response);
        if (response.status === 200 && response.data) {
          //Success
          setState({
            qrcodeBase64: response.data.qrcode,
            authKey: response.data.key,

            invalidCode: false,
            loadingSent: false,
            stageType: "qrcode",
          });
        } else {
          //Failure
          setMessages(
            "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateAuthEmail(code, setState, setMessages) {
    loginAPI
      .validateAuthEmail(code)
      .then((response) => {
        if (response.status === 200 && response.data) {
          //Success
          setState({
            qrcodeBase64: response.data.qrcode,
            authKey: response.data.key,

            invalidCode: false,
            loadingSent: false,
            stageType: "qrcode",
          });
        } else {
          //Failure
          setMessages(
            "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateAuth(props, redirectCallback, state, setState, setMessages) {
    loginAPI
      .validateAuth(state.code)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          //Success
          this.getClientValidationStatus(
            props,
            redirectCallback,
            state,
            setState
          );
        } else {
          //Failure
          setMessages(
            "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
          );
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages(
          "*sorry,-but-the-code-seems-to-be-wrong.-please-try-again!"
        );
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  getClientValidationStatus(props, redirectCallback, state, setState) {
    loginAPI
      .getClientConfig()
      .then((response) => {
        if (response.status === 200 && response.data) {
          if (response.data.email_validated) {
            //Code for adding auth and logging goes here
            redirectCallback(props, "/dashboard");
          } else {
            setState(
              {
                stageType: "email",
                loadingSent: false,
                inputKey: state.inputKey + 1,
                code: "",
                invalidCode: false,
                errorMessageType: "",
              },
              () => {
                this.confirmEmail(setState);
              }
            );
          }
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
      });
  }
}
