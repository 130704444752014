import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./changeEmail.css";
import ChangeEmailService from "./changegEmailService";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import GoBackButton from "../../../components/go-back-button/goBackButton";
import Result from "../../../components/result/result";
import Spinner from "react-bootstrap/Spinner";
import { utils } from "../../../utils/utils.jsx";
import ErrorMessageComponent from "../../../components/errorMessage/errorMessage.jsx";

class ChangeEmail extends Component {
  state = {
    isMobileWidth: false,
    email: "",
    newEmail: "",
    stage: 1,

    invalidNewEmail: false,

    resultSuccess: false,
    resultTitle: "",
    resultMessage: "",
    resultPrimaryButtonText: "",

    loadingSent: false,

    errorMessageType: "",
  };

  service = new ChangeEmailService();
  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.megastate &&
      this.props.location.megastate.email
    ) {
    } else {
      utils.goToURL(this.props, "/dashboard/settings");
    }

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard/settings"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard/settings"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {}

  changeEmail = () => {
    this.setState(
      {
        errorMessageType: "",
      },
      () => {
        if (this.state.newEmail != "") {
          if (this.emailRegex.test(this.state.newEmail)) {
            this.setState(
              {
                invalidNewEmail: false,
                errorMessageType: "",
                loadingSent: true,
              },
              () => {
                this.service.changeEmail(
                  this.state,
                  this.setState.bind(this),
                  this.setMessages.bind(this),
                  this.props.getClientConfig.bind(this)
                );
              }
            );
          } else {
            this.setMessages("*please-enter-a-valid-email");
            this.setState({
              invalidNewEmail: !this.emailRegex.test(this.state.newEmail),
            });
          }
        } else {
          this.setMessages("*one-or-more-required-field(s)-are-missing");
          this.setState({
            invalidNewEmail: this.state.newEmail === "",
          });
        }
      }
    );
  };

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="verify-info-container">
          <div class="side-container">
            <GoBackButton
              region={this.props.region}
              localizedText={this.props.localizedText}
              goBack={"/dashboard/settings"}
            ></GoBackButton>
          </div>
          <div class="middle-container container-padding">
            {this.state.stage === 1 ? (
              <div class="vendor-form-div">
                <div class="main-title">
                  {this.props.localizedText["change-email"]}
                </div>
                <div class="fade-in-up">
                  <p>
                    {
                      this.props.localizedText[
                        "*you-will-need-yo-verify-your-email-address-after-making-any-changes-to-it"
                      ]
                    }
                  </p>
                  <div class="div-parent-flex-column">
                    <Form.Group className="mb-3">
                      <Form.Label className="input-label-outside-div">
                        {this.props.localizedText["email"]}
                      </Form.Label>
                      <FormControl
                        type="text"
                        name="email"
                        value={
                          this.props.location &&
                          this.props.location.megastate &&
                          this.props.location.megastate.email
                            ? this.props.location.megastate.email
                            : ""
                        }
                        disabled
                        className="input-field"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="input-label-outside-div">
                        {this.props.localizedText["new-email"]}
                      </Form.Label>
                      <FormControl
                        name="newEmail"
                        value={this.state.newEmail}
                        onChange={(e) =>
                          utils.handleChange(e, this.setState.bind(this))
                        }
                        isInvalid={this.state.invalidNewEmail}
                        className="input-field"
                        disabled={this.state.loadingSent}
                      />
                    </Form.Group>
                    <ErrorMessageComponent
                      errorMessage={
                        this.props.localizedText[this.state.errorMessageType]
                      }
                    ></ErrorMessageComponent>
                    <div class="buttons-div">
                      <Button
                        className="primary-button outside-primary-button"
                        onClick={this.changeEmail}
                        disabled={this.state.loadingSent}
                      >
                        {this.state.loadingSent ? (
                          <Spinner className="loader-send-outside-div-button"></Spinner>
                        ) : null}
                        {this.props.localizedText["change"]}
                      </Button>

                      <Button
                        className="secondary-button outside-secondary-button"
                        onClick={utils.goToURL.bind(
                          this,
                          this.props,
                          "/dashboard/settings"
                        )}
                      >
                        {this.props.localizedText["cancel"]}
                      </Button>
                    </div>
                    <div class="version-number">
                      {process.env.REACT_APP_VERSION}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Result
                success={true}
                secondButton={true}
                title={this.props.localizedText["great-news!"]}
                message={
                  this.props.localizedText[
                    "the-email-was-successfully-changed!"
                  ]
                }
                primaryButtonText={this.props.localizedText["verify-email"]}
                primaryButtonFunction={utils.goToURL.bind(
                  this,
                  this.props,
                  "/dashboard/settings/verify-email"
                )}
                secondaryButtonText={this.props.localizedText["settings"]}
                secondaryButtonFunction={utils.goToURL.bind(
                  this,
                  this.props,
                  "/dashboard/settings"
                )}
              ></Result>
            )}
          </div>
          <div class="side-container"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ChangeEmail);
