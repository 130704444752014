import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./paymentOptions.css";
import GoBackButton from "../../../components/go-back-button/goBackButton";
import { FaBitcoin } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { IoIosArrowForward } from "react-icons/io";
import { RiBankFill, RiStarSFill } from "react-icons/ri";
import { IoLogoVenmo, IoLogoPaypal, IoCard } from "react-icons/io5";
import { utils } from "../../../utils/utils.jsx";
import { Link } from "react-router-dom";

class PaymentOptions extends Component {
  state = {
    isMobileWidth: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      "/dashboard"
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        "/dashboard"
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.phoneVerified != null && !this.props.phoneVerified) {
        utils.goToURL(this.props, "/dashboard/settings/verify-phone");
      } else if (
        this.props.emailVerified != null &&
        !this.props.emailVerified
      ) {
        utils.goToURL(this.props, "/dashboard/settings/verify-email");
      } else if (this.props.idVerified != null && !this.props.idVerified) {
        utils.goToURL(this.props, "/dashboard/settings/id-verification");
      }
    }
  }

  getIcon = (type) => {
    if (type == "vendors") {
      return <RiStarSFill class="icon"></RiStarSFill>;
    } else if (type == "in-app-users") {
      return <ImUsers class="icon"></ImUsers>;
    } else if (type == "bank") {
      return <RiBankFill class="icon"></RiBankFill>;
    } else if (type == "paypal") {
      return <IoLogoPaypal class="icon"></IoLogoPaypal>;
    } else if (type == "crypto") {
      return <FaBitcoin class="icon"></FaBitcoin>;
    } else if (type == "venmo") {
      return <IoLogoVenmo class="icon"></IoLogoVenmo>;
    } else if (type == "digital-card") {
      return <IoCard class="icon"></IoCard>;
    }
  };

  possibleDeposits = ({ deposits }) => (
    <div class="payment-option">
      <Link
        to="/dashboard/payment/vendors"
        class="payment-option-row-container"
      >
        <div class="payment-option-row">
          <div class="background"> </div>
          <div class="content">
            <div class="metadata-column">
              {this.getIcon("vendors")}
              <div class="text">
                <div class="title">{this.props.localizedText["vendors"]}</div>
                <div class="description">
                  {
                    this.props.localizedText[
                      "send-money-to-existing-or-new-vendors"
                    ]
                  }
                </div>
              </div>
            </div>

            <IoIosArrowForward class="arrow"></IoIosArrowForward>
          </div>
        </div>
      </Link>
      <Link
        class="payment-option-row-container"
        to="/dashboard/payment-in-app-users"
      >
        <div class="payment-option-row">
          <div class="background"> </div>
          <div class="content">
            <div class="metadata-column">
              {this.getIcon("in-app-users")}
              <div class="text">
                <div class="title">
                  {this.props.localizedText["in-app-users"]}
                </div>
                <div class="description">
                  {
                    this.props.localizedText[
                      "transfer-funds-to-registered-in-app-users"
                    ]
                  }
                </div>
              </div>
            </div>

            <IoIosArrowForward class="arrow"></IoIosArrowForward>
          </div>
        </div>
      </Link>
    </div>
  );

  render() {
    return (
      <React.Fragment>
        <div class="payment-options-container">
          <div class="payment-options-body container-padding">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={"/dashboard"}
              ></GoBackButton>
            </div>
            <div class="middle-container">
              <div class="payment-options-inside-div">
                <div class="main-title">
                  {this.props.localizedText["who-are-you-paying?"]}
                </div>
                <div class="payment-option-container">
                  <this.possibleDeposits></this.possibleDeposits>
                </div>
              </div>
            </div>
            <div class="side-container "></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PaymentOptions);
