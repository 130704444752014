import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./searchPages.css";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { ImSearch } from "react-icons/im";
import { IoIosArrowForward } from "react-icons/io";
import { utils } from "../../utils/utils";
import { Link } from "react-router-dom";

class SearchPages extends Component {
  state = {
    listPagesShowed: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setListPages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.region) {
        this.setListPages();
      }
    }
  }

  listPages = ({ pages }) => (
    <div class="div-pages">
      {pages.map((page, index) => (
        <Link
          class="page-row"
          onClick={utils.goToURL.bind(this, this.props, page.path)}
        >
          {page["name"]}
          <IoIosArrowForward class="arrow"></IoIosArrowForward>
        </Link>
      ))}
    </div>
  );

  setListPages = () => {
    this.setState({
      listPages: [
        {
          name: this.props.localizedText["assets"],
          path: "/dashboard/portfolio/assets",
        },
        {
          name: this.props.localizedText["change-email"],
          path: "/dashboard/settings",
        },
        {
          name: this.props.localizedText["change-password"],
          path: "/dashboard/settings",
        },
        {
          name: this.props.localizedText["conversion"],
          path: "/dashboard/conversion",
        },
        {
          name: this.props.localizedText["create-vendor"],
          path: "/dashboard/payment/vendors/create",
        },
        {
          name: this.props.localizedText["deposit"],
          path: "/dashboard/deposit",
        },
        {
          name: this.props.localizedText["id-verification"],
          path: "/dashboard/settings",
        },
        {
          name: this.props.localizedText["pay"],
          path: "/dashboard/payment-options",
        },
        {
          name: this.props.localizedText["pay-in-app-users"],
          path: "/dashboard/payment-input/:type",
        },
        {
          name: this.props.localizedText["pay-vendor"],
          path: "/dashboard/payment/vendors",
        },
        {
          name: this.props.localizedText["portfolio"],
          path: "/dashboard/payment/portfolio",
        },
        {
          name: this.props.localizedText["settings"],
          path: "/dashboard/settings",
        },
        {
          name: this.props.localizedText["transaction-history"],
          path: "/dashboard/portfolio/history",
        },
        {
          name: this.props.localizedText["verify-email"],
          path: "/dashboard/settings",
        },
        {
          name: this.props.localizedText["verify-password"],
          path: "/dashboard/settings",
        },
        {
          name: this.props.localizedText["withdraw"],
          path: "/dashboard/deposit-withdraw",
        },
      ],
      listPagesShowed: [],
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="search-pages-container">
          <div class="filter-search-div">
            <InputGroup className="search-bar">
              <ImSearch class="search-icon"></ImSearch>
              <FormControl
                onChange={(e) => {
                  utils.editSearchTermDefaultSearch(
                    e,
                    this.setState.bind(this),
                    this.state.listPages,
                    "listPagesShowed",
                    ["name"]
                  );
                }}
                type="search"
                id="search-bar-entities"
                placeholder={
                  this.props.localizedText["what-are-you-looking-for?"]
                }
                autoComplete="off"
              />
            </InputGroup>
          </div>

          <this.listPages pages={this.state.listPagesShowed}></this.listPages>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SearchPages);
