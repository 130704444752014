import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./sideNotificationsDiv.css";

class SideNotificationsDiv extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  goToExternalUrl = (url) => {
    window.location.replace(url);
  };

  render() {
    return (
      <React.Fragment>
        <div class="notifications-div">
          <a
            class="notification-card"
            href={process.env.REACT_APP_CARD_URL_1}
            target="_blank"
          >
            <div class="text">
              {this.props.localizedText["the-future"]}
              <line> {this.props.localizedText["is"]}</line>
              <div class="greent-text scroller">
                <span>
                  {this.props.localizedText["changing"]}
                  <br />
                  {this.props.localizedText["digital"]}
                  <br />
                  {this.props.localizedText["here"]}
                  <br />
                  {process.env.REACT_APP_COMPANY_NAME}
                </span>
              </div>
            </div>

            <div class="image image-src"></div>
          </a>
          <a
            class="notification-card"
            href={process.env.REACT_APP_CARD_URL_2}
            target="_blank"
          >
            <div class="text">
              {this.props.localizedText["vendors-or"]}
              <line> {this.props.localizedText["in-app-users"]} </line>
              <div class="higlight-text">
                {this.props.localizedText["pay-anyone"]}
              </div>
            </div>

            <div class="image image-src"></div>
          </a>
          <a
            class="notification-card"
            href={process.env.REACT_APP_CARD_URL_3}
            target="_blank"
          >
            <div class="text">
              {this.props.localizedText["got-a"]}
              <line> {this.props.localizedText["question?"]}</line>
              <div class="higlight-text">
                {this.props.localizedText["contact-us"]}
              </div>
              {this.props.localizedText["click"]}
            </div>

            <div class="image image-src"></div>
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SideNotificationsDiv);
