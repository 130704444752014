import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MdCheckCircle, MdOutlineError } from "react-icons/md";
import Button from "react-bootstrap/Button";
import "./result.css";

class TotalBalance extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div class="result-component-container">
          {this.props.success ? (
            <MdCheckCircle class="icon"></MdCheckCircle>
          ) : (
            <MdOutlineError class="icon"></MdOutlineError>
          )}
          <span class="result-title">{this.props.title}</span>
          <span class="description">{this.props.message}</span>

          <div class="buttons-div">
            <Button
              className="continue-button outside-primary-button"
              onClick={this.props.primaryButtonFunction}
            >
              {this.props.primaryButtonText}
            </Button>
            {this.props.secondButton ? (
              <Button
                className="back-button outside-secondary-button"
                onClick={this.props.secondaryButtonFunction}
              >
                {this.props.secondaryButtonText}
              </Button>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TotalBalance);
