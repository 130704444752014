import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./withdraw.css";
import WithdrawService from "./withdrawService.jsx";
import GoBackButton from "../../components/go-back-button/goBackButton.jsx";
import TotalBalance from "../../components/total-balance/totalBalance.jsx";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { utils } from "../../utils/utils.jsx";
import { RiBankFill } from "react-icons/ri";
import { SiBitcoinsv } from "react-icons/si";
import { FaMoneyBillWave } from "react-icons/fa";
import Result from "../../components/result/result";
import ConversionComponent from "../../components/conversion-component/conversionComponent";
import { MdCheckCircle } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import WithdrawBank from "../../components/withdraw/withdraw-bank/withdrawBank.jsx";
import WithdrawCrypto from "../../components/withdraw/withdraw-crypto/withdrawCrypto.jsx";
import WithdrawCash from "../../components/withdraw/withdraw-cash/withdrawCash.jsx";
import NoFundsComponent from "../../components/no-funds-component/noFundsComponent.jsx";

class Withdraw extends Component {
  state = {
    width: window.innerWidth,
    account: {},

    listPaymentProviders: [],
    listFiatWithdraw: [],
    listCryptoWithdraw: [],
    listNetworks: [],

    errorMessageType: "",

    currency: "",
    amount: "",
    accountType: "",
    accountNumber: "",
    bankID: "",
    note: "",

    walletAddress: "",
    token: "",
    network: {},

    code: "",
    authChoice: "",
    confirmPhoneMessage: "",

    withdrawCashResponse: {},

    loadingPaymentProviders: true,
    loadingCurrenciesWithdraw: true,
    loadingNetworks: true,
    loadingSent: false,

    invalidCurrency: false,
    invalidAmount: false,
    invalidAccountType: false,
    invalidAccountNumber: false,
    invalidBankID: false,
    invalidWalletAddress: false,
    invalidToken: false,
    invalidNetwork: false,
    invalidCode: false,

    resultSuccess: true,

    stage: 1,
  };

  service = new WithdrawService();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.phoneVerified != null && !this.props.phoneVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-phone");
    } else if (this.props.emailVerified != null && !this.props.emailVerified) {
      utils.goToURL(this.props, "/dashboard/settings/verify-email");
    } else if (this.props.idVerified != null && !this.props.idVerified) {
      utils.goToURL(this.props, "/dashboard/settings/id-verification");
    }

    this.service.getClientConfig(this.setState.bind(this));
    this.service.getCurrenciesWithdraw(this.setState.bind(this));

    utils.checkWindowWidth(
      this.setState.bind(this),
      this.props.changeGoBackNavBar.bind(this),
      true,
      this.tryToGoBackStage
    );
    window.addEventListener(
      "resize",
      utils.checkWindowWidth.bind(
        this,
        this.setState.bind(this),
        this.props.changeGoBackNavBar.bind(this),
        true,
        this.tryToGoBackStage
      )
    );

    if (this.props.account) {
      this.setState({
        account: this.props.account,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props != prevProps) {
      if (this.props.account) {
        this.setState({
          account: this.props.account,
        });
      }
    }
  }

  setMessages = (type) => {
    this.setState({
      errorMessageType: type,
    });
  };

  moveStage = (stage) => {
    this.setState({
      stage: stage,
    });
  };

  goBackStage = () => {
    this.setState({
      stage: 1,
      currency: "",
      amount: "",
      accountType: "",
      accountNumber: "",
      bankID: "",
      note: "",
      token: "",
      network: {},
      walletAddress: "",
      errorMessageType: "",
      invalidCurrency: false,
      invalidAmount: false,
      invalidAccountType: false,
      invalidAccountNumber: false,
      invalidBankID: false,
      invalidWalletAddress: false,
      invalidToken: false,
      invalidNetwork: false,
      loadingConversion: false,
    });
  };

  tryToGoBackStage = () => {
    if (this.state.stage == 1) {
      utils.goToURL(this.props, "/dashboard");
    } else {
      this.goBackStage();
    }
  };

  goToResult = (success) => {
    this.setState({
      stage: 6,
      resultSuccess: success,
    });
  };

  goToResultCash = (success, withdrawCashResult) => {
    if (success) {
      this.setState({
        stage: 5,
        withdrawCashResult: withdrawCashResult,
      });
    } else {
      this.setState({
        stage: 6,
        resultSuccess: false,
      });
    }
  };

  getResultMessage = () => {
    if (this.state.resultSuccess) {
      return this.props.localizedText["you-have-successfully-withdrawn"];
    } else {
      return this.props.localizedText[
        "unfortunately,-there-was-a-problem-with-the-withdraw"
      ];
    }
  };

  setCurrency = (value) => {
    this.setState({
      currency: value,
    });
  };

  setCrypto = (value) => {
    this.setState({
      crypto: value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div class="withdraw-container">
          <div class="withdraw-body">
            <div class="side-container">
              <GoBackButton
                region={this.props.region}
                localizedText={this.props.localizedText}
                goBack={this.tryToGoBackStage}
              ></GoBackButton>
            </div>
            <div class="middle-container container-padding">
              {(this.state.stage == 1 ||
                this.state.stage == 2 ||
                this.state.stage == 3 ||
                this.state.stage == 4 ||
                this.state.stage == 5) &&
              (this.state.listFiatWithdraw.length > 0 ||
                this.state.listCryptoWithdraw.length > 0 ||
                this.state.loadingCurrenciesWithdraw) ? (
                <div>
                  {" "}
                  <TotalBalance
                    account={this.state.account}
                    region={this.props.region}
                    localizedText={this.props.localizedText}
                  ></TotalBalance>
                  <div class="withdraw-title fade-in-up">
                    {this.props.localizedText["withdraw"]}
                  </div>
                  {this.state.stage == 1 ? (
                    <div>
                      {this.state.loadingCurrenciesWithdraw ? (
                        <div class="fade-in-up">
                          <Spinner className="loader loading-currencies" />
                        </div>
                      ) : (
                        <div>
                          <div class="section">
                            <div class="options-div">
                              {" "}
                              <div class="sub-title">
                                {
                                  this.props.localizedText[
                                    "*please-choose-a-withdraw-type-from-the-options-available-on-the-right"
                                  ]
                                }
                              </div>
                              <div class="withdraw-list">
                                {this.state.listFiatWithdraw.length > 0 ? (
                                  <div
                                    class="withdraw-row-container"
                                    onClick={this.moveStage.bind(this, 2)}
                                  >
                                    <div class="withdraw-row">
                                      <div class="background"> </div>
                                      <div class="content">
                                        <div class="metadata-column">
                                          <RiBankFill class="icon" />
                                          <div class="text">
                                            <div class="option-name">
                                              {" "}
                                              {this.props.localizedText["bank"]}
                                            </div>
                                            <div class="option-description">
                                              {
                                                this.props.localizedText[
                                                  "withdraw-funds-directly-into-your-bank-account"
                                                ]
                                              }
                                            </div>
                                          </div>
                                        </div>{" "}
                                        <IoIosArrowForward class="arrow"></IoIosArrowForward>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}{" "}
                                {this.state.listCryptoWithdraw.length > 0 ? (
                                  <div
                                    class="withdraw-row-container"
                                    onClick={this.moveStage.bind(this, 3)}
                                  >
                                    <div class="withdraw-row">
                                      <div class="background"> </div>
                                      <div class="content">
                                        <div class="metadata-column">
                                          <SiBitcoinsv class="icon" />
                                          <div class="text">
                                            <div class="option-name">
                                              {" "}
                                              {
                                                this.props.localizedText[
                                                  "crypto"
                                                ]
                                              }
                                            </div>
                                            <div class="option-description">
                                              {
                                                this.props.localizedText[
                                                  "withdraw-funds-to-your-crypto-wallet"
                                                ]
                                              }
                                            </div>
                                          </div>
                                        </div>{" "}
                                        <IoIosArrowForward class="arrow"></IoIosArrowForward>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}{" "}
                                {this.state.listFiatWithdraw.length > 0 ? (
                                  <div
                                    class="withdraw-row-container"
                                    onClick={this.moveStage.bind(this, 4)}
                                  >
                                    <div class="withdraw-row">
                                      <div class="background"> </div>
                                      <div class="content">
                                        <div class="metadata-column">
                                          <FaMoneyBillWave class="icon" />
                                          <div class="text">
                                            <div class="option-name">
                                              {" "}
                                              {this.props.localizedText["cash"]}
                                            </div>
                                            <div class="option-description">
                                              {
                                                this.props.localizedText[
                                                  "withdraw-funds-in-cash"
                                                ]
                                              }
                                            </div>
                                          </div>
                                        </div>{" "}
                                        <IoIosArrowForward class="arrow"></IoIosArrowForward>
                                      </div>{" "}
                                    </div>
                                  </div>
                                ) : null}{" "}
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  ) : null}
                  {this.state.stage == 2 &&
                  this.state.listFiatWithdraw.length > 0 ? (
                    <WithdrawBank
                      changeGoBackNavBar={this.props.changeGoBackNavBar}
                      localizedText={this.props.localizedText}
                      listFiatWithdraw={this.state.listFiatWithdraw}
                      tryToGoBackStage={this.tryToGoBackStage}
                      setCurrency={this.setCurrency}
                      goToResult={this.goToResult}
                    />
                  ) : null}{" "}
                  {this.state.stage == 3 &&
                  this.state.listCryptoWithdraw.length > 0 ? (
                    <div>
                      {this.state.loadingCurrenciesWithdraw ? (
                        <div class="fade-in-up">
                          <Spinner className="loader loading-currencies" />
                        </div>
                      ) : (
                        <WithdrawCrypto
                          changeGoBackNavBar={this.props.changeGoBackNavBar}
                          localizedText={this.props.localizedText}
                          listCryptoWithdraw={this.state.listCryptoWithdraw}
                          tryToGoBackStage={this.tryToGoBackStage}
                          setCrypto={this.setCrypto}
                          goToResult={this.goToResult}
                        />
                      )}
                    </div>
                  ) : null}{" "}
                  {this.state.stage == 4 &&
                  this.state.listFiatWithdraw.length > 0 ? (
                    <WithdrawCash
                      changeGoBackNavBar={this.props.changeGoBackNavBar}
                      localizedText={this.props.localizedText}
                      listFiatWithdraw={this.state.listFiatWithdraw}
                      tryToGoBackStage={this.tryToGoBackStage}
                      goToResult={this.goToResultCash}
                      setCurrency={this.setCurrency}
                    />
                  ) : null}{" "}
                  {this.state.stage == 5 ? (
                    <div class="withdraw-cash-result-div">
                      <MdCheckCircle className="withdraw-cash-result-icon"></MdCheckCircle>
                      <span class="withdraw-cash-result-title">
                        {this.props.localizedText["good-news!"]}
                      </span>

                      <div class="withdraw-cash-result-data-div">
                        <div class="withdraw-cash-result-data-row">
                          {" "}
                          <div class="sub-title">
                            {this.props.localizedText["otp"]}
                          </div>{" "}
                          <div>{this.state.withdrawCashResult.otp}</div>{" "}
                        </div>
                        <div class="withdraw-cash-result-data-row">
                          {" "}
                          <div class="sub-title">
                            {this.props.localizedText["contract-number"]}
                          </div>{" "}
                          <div>
                            {this.state.withdrawCashResult.contract_number}
                          </div>{" "}
                        </div>
                        <div class="withdraw-cash-result-data-row">
                          {" "}
                          <div class="sub-title">
                            {this.props.localizedText["amount-in-usd"]}
                          </div>{" "}
                          <div>{this.state.withdrawCashResult.usd_amount}</div>{" "}
                        </div>
                        <div class="withdraw-cash-result-data-row">
                          {" "}
                          <div class="sub-title">
                            {this.props.localizedText["operation"]}
                          </div>{" "}
                          <div>{this.state.withdrawCashResult.operation}</div>{" "}
                        </div>
                      </div>

                      <div class="buttons-div">
                        <Button
                          className="continue-button outside-primary-button"
                          onClick={utils.goToURL.bind(
                            this,
                            this.props,
                            "/dashboard"
                          )}
                        >
                          {this.props.localizedText["continue"]}
                        </Button>
                      </div>
                    </div>
                  ) : null}{" "}
                </div>
              ) : null}
              {!this.state.loadingCurrenciesWithdraw &&
              this.state.listFiatWithdraw.length <= 0 &&
              this.state.listCryptoWithdraw.length <= 0 ? (
                <NoFundsComponent
                  localizedText={this.props.localizedText}
                  primaryButtonFunction={utils.goToURL.bind(
                    this,
                    this.props,
                    "/dashboard/settings"
                  )}
                  secondaryButtonFunction={utils.goToURL.bind(
                    this,
                    this.props,
                    "/dashboard/"
                  )}
                />
              ) : null}
              {this.state.stage == 6 ? (
                <Result
                  success={this.state.resultSuccess}
                  secondButton={false}
                  title={
                    this.props.localizedText[
                      utils.getResultTitle(this.state.resultSuccess)
                    ]
                  }
                  message={this.getResultMessage()}
                  primaryButtonText={this.props.localizedText["continue"]}
                  primaryButtonFunction={utils.goToURL.bind(
                    this,
                    this.props,
                    "/dashboard"
                  )}
                ></Result>
              ) : null}
            </div>
            <div class="side-container ">
              {this.state.stage == 2 && this.state.currency ? (
                <ConversionComponent
                  currencyFrom={this.state.currency}
                  currencyTo={"USD"}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></ConversionComponent>
              ) : null}
              {this.state.stage == 3 && this.state.token ? (
                <ConversionComponent
                  currencyFrom={this.state.token}
                  currencyTo={"USD"}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></ConversionComponent>
              ) : null}
              {this.state.stage == 4 && this.state.currency ? (
                <ConversionComponent
                  currencyFrom={this.state.currency}
                  currencyTo={"USD"}
                  region={this.props.region}
                  localizedText={this.props.localizedText}
                ></ConversionComponent>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Withdraw);
