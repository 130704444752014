import { portfolioAPI } from "../../../apis/portfolioAPI";
import { loginAPI } from "../../../apis/loginAPI";
import { withdrawAPI } from "../../../apis/withdrawAPI";
import { refdataAPI } from "../../../apis/refdataAPI";
import { swapAPI } from "../../../apis/swapAPI.jsx";
import { walletAPI } from "../../../apis/walletAPI.jsx";
import { utils } from "../../../utils/utils.jsx";

export default class WithdrawCashService {
  withdrawCash(currency, amount, note, setState, goToResult) {
    portfolioAPI
      .getDefaultPortfolio()
      .then((response) => {
        if (response.status === 200 && response.data) {
          withdrawAPI
            .withdrawCash(response.data.name, currency, amount, note)
            .then((response) => {
              console.log("simple_funds_out_cash", response);
              if (response.status === 200 && response.data) {
                goToResult(true, JSON.parse(response.data.payload));
              } else {
                goToResult(false, null);
              }
            })
            .catch((error) => {
              //Failure
              console.log("simple_funds_out_cash error", response);
              goToResult(false, null);
            });
        } else {
          console.log("getDefaultPortfolio failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("getDefaultPortfolio error", error);
      });
  }

  //get currencies crypto
  getDefaultPortfolio(setState, listCurrenciesWithdrawFiat) {
    portfolioAPI
      .getDefaultPortfolio()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("success", response);
          this.getPortfolioBalances(
            setState,
            response.data.name,
            listCurrenciesWithdrawFiat
          );
        } else {
          console.log("failure", response);
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getPortfolioBalances(setState, portfolioName, listCurrenciesWithdrawFiat) {
    portfolioAPI
      .getPortfolioBalances(portfolioName)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let listCurrenciesFiatAvailable = [];
          let fiatRequests = [];
          let fiatResponses = [];
          let auxCurrency = {};

          console.log("listCurrenciesWithdrawFiat", listCurrenciesWithdrawFiat);
          console.log(
            "listCryptoWithdraw",
            response.data.filter((element) => !element.fiat)
          );
          for (let i = 0; i < listCurrenciesWithdrawFiat.length; i++) {
            //conversion for fiat
            fiatRequests.push(listCurrenciesWithdrawFiat[i].base_currency);
            fiatResponses.push(
              swapAPI.convertEstimate(
                response.data.filter(
                  (element) => element.base_currency == "USD"
                )[0].qty,
                "USD",
                listCurrenciesWithdrawFiat[i].base_currency,
                0
              )
            );
          }
          Promise.all(fiatResponses)
            .then((fiatResponses) => {
              console.log("fiatResponse", fiatResponses);
              for (let i = 0; i < fiatResponses.length; i++) {
                auxCurrency = listCurrenciesWithdrawFiat.filter(
                  (element) => element.base_currency == fiatRequests[i]
                )[0];
                auxCurrency["available_qty"] =
                  fiatResponses[i].data &&
                  fiatResponses[i].data.amount_to_receive &&
                  fiatResponses[i].data.amount_to_send
                    ? fiatResponses[i].data.amount_to_receive
                    : response.data.filter(
                        (element) => element.base_currency == fiatRequests[i]
                      )[0].qty;

                listCurrenciesFiatAvailable.push(auxCurrency);
              }

              console.log(
                "listCurrenciesFiatAvailable",
                listCurrenciesFiatAvailable
              );

              setState({
                listFiatWithdraw: listCurrenciesFiatAvailable,
                listCryptoWithdraw: response.data.filter(
                  (element) => !element.fiat
                ),
                loadingCurrenciesWithdraw: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log("error", error);
      });
  }

  getClientConfig(setState) {
    loginAPI
      .getClientConfig()
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("config", response);
          setState({
            authChoice:
              response.data.auth_choice == "auth_pending" ||
              response.data.auth_choice == "auth_declined"
                ? "Phone"
                : "Google Auth",
          });

          if (
            response.data.auth_choice == "auth_pending" ||
            response.data.auth_choice == "auth_declined"
          ) {
            this.confirmPhone(setState);
          }
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  confirmPhone(setState) {
    loginAPI
      .confirmPhone()
      .then((response) => {
        if (response.status === 200) {
          //Success
          var mySubString = response.data.payload.substring(
            response.data.payload.indexOf("[") + 1,
            response.data.payload.lastIndexOf("]]")
          );

          setState({
            confirmPhoneMessage:
              "xxx-xxx-" +
              mySubString.slice(mySubString.length - 3, mySubString.length),
          });
        } else {
          //Failure
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
      });
  }

  validatePhone(code, setState, setMessages, doWithdraw) {
    loginAPI
      .validatePhone(code)
      .then((response) => {
        console.log("responssse", response);
        if (response.status === 200 && response.data.success) {
          //Success
          doWithdraw();
        } else {
          //Failure
          setMessages("*we-couldn't-validate-the-entered-code");
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("*we-couldn't-validate-the-entered-code");
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }

  validateAuth(code, setState, setMessages, doWithdraw) {
    loginAPI
      .validateAuth(code)
      .then((response) => {
        console.log("responssse", response);
        if (response.status === 200 && response.data.success) {
          //Success
          doWithdraw();
        } else {
          //Failure
          setMessages("*we-couldn't-validate-the-entered-code");
          setState({
            invalidCode: true,
            loadingSent: false,
          });
        }
      })
      .catch((error) => {
        //Failure
        console.log(error);
        setMessages("*we-couldn't-validate-the-entered-code");
        setState({
          invalidCode: true,
          loadingSent: false,
        });
      });
  }
}
